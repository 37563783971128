import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useApi } from "../../../../api/api";
import Button1 from "../../common/GlobalButtonLayout/Button1";
import { useDispatch, useSelector } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { aboutDetail } from "../../../../store/authenticationSlice";
import globalToast from "../../../../hooks/globalToast";

const GeosUpdateComponent = ({ openGeoModal, setOpenGeoModal }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const profileData = useSelector((state) => state.auth.profileData);

  const isDark = useSelector((state) => state.dark.isDarkValue);
  const { handlePostRequest } = useApi();
  const handleCloseCreatePlaylistTrackModal = () => {
    setOpenGeoModal(false);
  };
  const [selectedWorldMapCountry, setSelectedWorldMapCountry] = useState(
    profileData.Favourite.geo_locations
  );

  const [tooltipContain, setTooltipContent] = useState({
    show: false,
    x: 0,
    y: 0,
    content: "",
  });
  useEffect(() => {
    setSelectedWorldMapCountry(profileData.Favourite.geo_locations);
  }, [profileData]);

  const onWorldMapHandler = useCallback((selectedWorldMap, geo) => {
    const id = selectedWorldMap?.id?.substring(0, 2);

    setSelectedWorldMapCountry((prev) => {
      const isItemAlreadySelected = prev.includes(id);

      if (isItemAlreadySelected) {
        // If item is already selected, remove it from the array
        return prev.filter((item) => item !== id);
      } else {
        // If item is not selected, add it to the array
        return [...prev, id];
      }
    });
  }, []);

  const onSubmit = async () => {
    if (selectedWorldMapCountry.length === 0) {
      globalToast(
        "Please select your preferred music-listening country to continue",
        true
      );
      return;
    }

    const instruPayload = {
      scope: "geo",
      ids: selectedWorldMapCountry,
    };

    const setlng = {
      status: "geo",
      geo: selectedWorldMapCountry,
    };
    const generatePlaylistRes = await handlePostRequest(
      `profile-edit-preferences`,
      instruPayload,
      true,
      true
    );

    dispatch(aboutDetail(setlng));
    setOpenGeoModal(false);
  };

  return (
    <Dialog
      // fullScreen={fullScreen}
      open={openGeoModal}
      maxWidth={"lg"}
      // onClose={handleCloseCreatePlaylistTrackModal}
      aria-labelledby="responsive-dialog-title"
      className={`lg:w-screen ${isDark ? "dark" : ""} `}
    >
      <div className="dark:bg-[#0D0D0D] lg:py-3 py-1 px-3 lg:px-8 lg:min-w-[920px] lg:max-w-[1120px]    h-[100%] lg:h-auto ">
        <div className="flex flex-col ">
          <div className="flex w-full justify-between mb-2 items-center">
            {" "}
            <h6 className="text-black text-[16px] lg:text-[24px] dark:text-white font-medium  my-3">
              Where does the music that inspires you come from ?
            </h6>
            <CancelOutlinedIcon
              onClick={() => handleCloseCreatePlaylistTrackModal()}
              className=" cursor-pointer dark:!text-[#A8A8A8] "
            />
          </div>

          <div className={`  overflow-y-hidden w-[95%] mx-auto  `}>
            <div id="map-container" className=" h-full ">
              <ComposableMap
                width={1300}
                height={550}
                projectionConfig={{ scale: 180 }}
              >
                <ZoomableGroup
                  zoom={true}
                  translateExtent={[
                    [0, -700],
                    [1300, 700],
                  ]}
                >
                  <Geographies geography="/features.json">
                    {({ geographies }) =>
                      geographies?.map((geo) => (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          onMouseEnter={(event) => {
                            const mapContainer =
                              document.getElementById("map-container");
                            const rect = mapContainer.getBoundingClientRect();
                            const x = event.clientX - rect.left;
                            const y = event.clientY - rect.top;
                            setTooltipContent({
                              show: true,
                              x,
                              y,
                              content: geo?.properties?.name,
                            });
                          }}
                          onMouseLeave={() => {
                            setTooltipContent((tooltipValue) => {
                              return { ...tooltipValue, show: false };
                            });
                            setTooltipContent("");
                          }}
                          //   onClick={() => onWorldMapHandler(geo)}
                          onClick={(event) => {
                            // Call the onWorldMapHandler function
                            onWorldMapHandler(geo);
                            event.target.blur();
                          }}
                          style={{
                            default: {
                              fill: selectedWorldMapCountry?.includes(
                                geo?.id?.substring(0, 2)
                              )
                                ? "#F1B942"
                                : "#D8D8D8",
                            },
                            height: "60%",
                            hover: {
                              fill: "#D8D8D8",
                              stroke: "#F1B942",
                              cursor: "pointer",
                            },
                            pressed: {
                              fill: "blue",
                            },
                            // Add additional CSS to remove outline/focus border
                            focus: {
                              outline: "none",
                            },
                          }}
                        />
                      ))
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
              {tooltipContain.show && (
                <div
                  style={{
                    position: "absolute",
                    left: "45%", // Adjust the tooltip's left position
                    top: "20%", // Adjust the tooltip's top position
                    backgroundColor: "white",
                    padding: "4px",
                    borderRadius: "4px",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {tooltipContain.content}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-center items-center mt-[50px] lg:mt-[10px]">
            <div className="w-[104px]">
              <Button1
                type="submit"
                onClick={onSubmit}
                className=" mx-auto text-center ml-auto dark:hover:!text-[#F3C25A]"
              >
                Save
              </Button1>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GeosUpdateComponent;
