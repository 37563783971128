import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import QueueMusicOutlinedIcon from "@mui/icons-material/QueueMusicOutlined";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import { useApi } from "../../../api/api";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCurrentSongIndex,
  handleIsRecommended,
  handlePlayerStatus,
} from "../../../store/playerSlice";
import {
  handleCountSongs,
  handleLikeSongs,
} from "../../../store/trackLikeSlice";
import H220 from "../common/Typograpgy/H220";
import ShareTrack from "../common/TracksComponents/ShareTrack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import globalToast from "../../../hooks/globalToast";
import Button2 from "../common/GlobalButtonLayout/Button2";
import CreatePlaylistModal from "../common/TracksComponents/CreatePlaylistModal";
import toast, { Toaster } from "react-hot-toast";
import AddToPlaylist from "../common/TracksComponents/AddToPlaylist";

const SharedSong = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { handleGetRequest, handlePostRequest } = useApi();
  const [items, setItems] = useState();
  const [open, setOpen] = useState(false);
  const [trackId, setTrackId] = useState();

  const [searchQuery, setSearchQuery] = useState("");
  const [createPlaylistModal, setCreatePlaylistModal] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);
  const countTracks = useSelector((state) => state.likeSongs.countSongs);
  const likedTracks = useSelector((state) => state.likeSongs.likedSongs);
  const trackData = useSelector((state) => state.tracks.trackData);

  const [openDropdownId, setOpenDropdownId] = useState(null);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [seaQuery, setSeaQuery] = useState("");
  const handleSearchChange = (event) => {
    setSeaQuery(event.target.value);
  };

  const filteredPlaylists = playlistData?.filter((playlist) =>
    playlist.v_name.toLowerCase()?.includes(seaQuery.toLowerCase())
  );

  useEffect(() => {
    (async () => {
      const songDataReq = await handleGetRequest(
        `song/${id}`,

        false,
        true
      );
      setItems(songDataReq.data);
      console.log("artistsDetailDataReq", songDataReq);
    })();
  }, [id]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [createPlaylistCode, setCreatePlaylistCode] = useState("");
  const [createPlaylistId, setCreatePlaylistId] = useState();

  const [playlistId, setPlaylistId] = useState("");

  const [openCreatePlaylistTrackModal, setOpenCreatePlaylistTrackModal] =
    useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds = trackData.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleClickOpenCreatePlaylistTrackModal = (id) => {
    console.log("tumhh");

    if (selectedRows.length < 1 && !id) {
      // toast.error("Select at least 1 track");
      globalToast("Select at least 1 track", true);
      return;
    }
    setOpenCreatePlaylistTrackModal(true);
    const newUrl = `${location.pathname}?${Date.now()}`;
    window.history.pushState({}, "", newUrl);
    setCreatePlaylistId([id]);
  };

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };
  const location = useLocation();

  const [shareId, setShareId] = useState();
  const playSong = async (songData, currentSongIndex, id) => {
    // dispatch(handleCurrentSongIndex(-1));
    // setTimeout(() => {
    countTracks?.map((item) => {
      console.log(
        "dsfsf",
        id,
        item?.id,
        item?.play_count,
        id === item?.id
          ? { ...item, play_count: parseInt(item?.play_count, 10) || 0 + 1 }
          : item
      );
    });
    dispatch(
      handleCountSongs(
        countTracks.map((item) =>
          item.id === id ? { ...item, play_count: item.play_count + 1 } : item
        )
      )
    );

    const listenCountRes = await handlePostRequest(
      `song-listen-count/${id}`,
      false,
      false
    );
    const songUrl = JSON.parse(localStorage.getItem("playingCurrentSong"));

    if (songUrl?.id === id) {
      console.log("songUrlcurrentSongIndex", songUrl);

      // dispatch(handleCurrentSongIndex(-1));
      dispatch(handlePlayerStatus(false));

      setTimeout(() => {
        dispatch(handleIsRecommended(false));
        console.log("currentSongIndex", currentSongIndex);
        localStorage.setItem("playingCurrentSong", JSON.stringify(songData));
        dispatch(handlePlayerStatus(songData?.e_platform));
      }, 1000);
      return;
    }

    dispatch(handleIsRecommended(false));
    console.log("currentSongIndex", currentSongIndex);
    localStorage.setItem("playingCurrentSong", JSON.stringify(songData));
    dispatch(handlePlayerStatus(songData?.e_platform));

    // }, 100);

    // Update currentSongIndex to -1 or a temporary value for half a second
    if (currentSongIndex === 0) {
      dispatch(handleCurrentSongIndex(-1));
      setTimeout(() => {
        dispatch(handleCurrentSongIndex(1));
      }, 100);
    } else {
      const tempIndex = currentSongIndex * -1; // Invert the sign for temporary value
      // dispatch(handleCurrentSongIndex(tempIndex));
      dispatch(handleCurrentSongIndex(currentSongIndex));

      // setTimeout(() => {
      //     dispatch(handleCurrentSongIndex(currentSongIndex));
      // }, 100);
    }
  };
  const handleLike = async (trackId) => {
    const updatedLikedTracks = likedTracks?.includes(trackId)
      ? likedTracks.filter((id) => id !== trackId)
      : [...likedTracks, trackId];
    dispatch(handleLikeSongs(updatedLikedTracks));
    // setLikedTracks(updatedLikedTracks);

    // Send API request to update like status on the server
    updateLikeStatus(trackId, updatedLikedTracks?.includes(trackId));
  };

  const updateLikeStatus = async (trackId, isLiked) => {
    const trackLikeRes = await handleGetRequest(
      `song-favourite?id=${trackId}&type=${isLiked ? "Like" : "Dislike"}`,
      true,
      false
    );
  };
  const handleAddToPlaylist = async (playlistCode) => {
    if (!playlistCode) {
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 `}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex justify-start items-center">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src="/1922.png"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                {/* <p className="text-sm font-medium text-white">
                          Emilia Gates
                        </p> */}
                <p className="mt-1 text-sm text-black  flex justify-start items-center font-semibold">
                  Please select file
                </p>
              </div>
            </div>
          </div>
        </div>
      ));
      return;
    }
    const payload = {
      track_id: trackId,
      v_code: playlistCode,
    };
    const addPlaylistRes = await handlePostRequest(
      `${`add-song-to-playlist`}`,
      payload,
      true,
      true
    );
    console.log("addPlaylistRes", addPlaylistRes);
    setCreatePlaylistModal(false);
  };
  const getPlaylistData = async (id, platform) => {
    setTrackId(id);
    const playlistRes = await handleGetRequest(
      `${`playlist-data?e_platform_name=default&type=all&search_keyword=${searchQuery}&sort_type=new`}`,
      false,
      false
    );
    setPlaylistData(playlistRes?.data);
    const newUrl = `${location.pathname}?${Date.now()}`;
    window.history.pushState({}, "", newUrl);
  };
  console.log(items, "items");
  return (
    <div>
      <ShareTrack
        id={items?.id}
        location={`https://curioushit-frontend.nevinainfotech.in/playlist/${items?.id}`}
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        setShareId={setShareId}
      />

      <CreatePlaylistModal
        setSelectAll={setSelectAll}
        openCreatePlaylistTrackModal={openCreatePlaylistTrackModal}
        setOpenCreatePlaylistTrackModal={setOpenCreatePlaylistTrackModal}
        id={selectedRows.length > 0 ? selectedRows : createPlaylistId}
        setSelectedRows={setSelectedRows}
        setCreatePlaylistCode={setCreatePlaylistCode}
        createPlaylistCode={createPlaylistCode}
        setCreatePlaylistId={setPlaylistId}
      />
      {createPlaylistModal && (
        <AddToPlaylist
          setCreatePlaylistModal={setCreatePlaylistModal}
          trackId={trackId}
          playlistData={playlistData}
          handleAddToPlaylist={handleAddToPlaylist}
          handleClickOpenCreatePlaylistTrackModal={
            handleClickOpenCreatePlaylistTrackModal
          }
        />
      )}
      <H220 className="!px-2 lg:px-0">Shared Tracks</H220>

      {items != undefined && Object.keys(items).length !== 0 ? (
        <table className="mt-3 w-full">
          <thead className={`bg-white dark:bg-[#151515] rounded-xl `}>
            <tr className="capitalize  lg:text-center text-[#A1A1A1] text-sm font-semibold ">
              <th className="px-2 py-3 text-start lg:text-center lg:rounded-tl-xl font-semibold capitalize ">
                Title
              </th>
              <th className="px-2 py-3 hidden lg:table-cell font-semibold capitalize ">
                {/* {isMusicAnalyzer ? "View File" : "Genres"} */}
                Genres
              </th>
              <th className="px-2 py-3 hidden lg:table-cell font-semibold">
                Listened
              </th>
              <th className="px-2 py-3 hidden lg:table-cell font-semibold capitalize ">
                Source
              </th>
              <th className="px-2 py-3 hidden lg:table-cell font-semibold">
                Duration
              </th>{" "}
              <th className="px-2 py-3  lg:table-cell text-end lg:rounded-tr-xl lg:text-center font-semibold">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className={`bg-white dark:bg-[#151515] lg:rounded-b-xl`}>
            <tr className="rounded-xl">
              <td
                onClick={() => playSong(items, items?.id, items?.id)}
                className="!text-left py-3 flex justify-start items-center gap-x-2 px-3"
              >
                <div className="w-[50px] h-[50px]">
                  <img
                    src={`${items?.t_image_path}`}
                    alt="img"
                    className="object-cover h-full w-full  rounded-md cursor-pointer"
                  />
                </div>

                {/* <div className="w-[50%] overflow-hidden cursor-pointer" onClick={()=>{handlePlay(index,items?.id,items?.t_title);dispatch(recommendStatus(false))}}> */}
                <div className="w-[50%] overflow-hidden cursor-pointer">
                  <Tooltip title={items?.t_title}>
                    <p className="text-[#312522]  text-xs font-semibold mb-1 lg:mb-2 line-clamp-1 text-wrap dark:text-[#DDD1CE] capitalize">
                      {items?.t_title}
                    </p>
                  </Tooltip>
                  <Tooltip title={items?.artistlist}>
                    <p className="text-[#B7B7B7] capitalize  truncate text-xs dark:text-[#DDD1CE]">
                      {items?.artistlist}
                    </p>
                  </Tooltip>
                </div>
              </td>
              <td className="!text-left w-[20%]  hidden lg:table-cell">
                {items?.t_genres_display && (
                  <div>
                    <p className="text-[#3871E4] px-2 bg-white border-[1px] border-[#3871E4] dark:bg-[#1A1A1A] dark:text-[#DDD1CE] text-center w-2/4 mx-auto rounded-[30px] py-1 text-xs font-normal mb-1 lg:mb-2 truncate ">
                      {items?.t_genres_display}
                    </p>
                  </div>
                )}
              </td>
              <td className="!text-left w-[24%]  hidden lg:table-cell">
                <div className="flex justify-center gap-x-1 items-center">
                  <HeadphonesIcon className="!w-4 lg:w-auto dark:!text-[#DDD1CE]" />
                  {/* <p className="text-[#312522] text-sm font-light">
                                       {!listenCount?.i_play_count ? items?.play_count : listenCount?.song_id===items?.id?listenCount?.i_play_count:items?.play_count}
                                       </p> */}
                  {/* {console.log("sdasda",items?.id, countTracks?.find(item => item.id === items?.id)?.play_count || 0)} */}
                  <p className="text-[#312522] text-sm font-light dark:text-[#DDD1CE]">
                    {/* {items?.id===countTracks?.id && countTracks?.play_count} */}
                    {/* {countTracks.find(item => item.id === items?.id?item?.play_count:0) } */}

                    {countTracks?.find((item) => item.id === items?.id)
                      ?.i_own_play_count || 0}
                  </p>
                </div>
              </td>{" "}
              <td className="!text-left w-[20%] hidden lg:table-cell">
                <div>
                  {/* <p className="text-[#312522] line-clamp-1 text-center text-xs font-normal mb-1 lg:mb-2 dark:text-[#DDD1CE]">{items?.albumlist}</p> */}
                  <p className="text-[#312522] line-clamp-1 text-center text-xs font-normal mb-1 lg:mb-2 dark:text-[#DDD1CE]">
                    {items?.e_platform === "Default" ? "Upload" : "Sync"}
                  </p>
                </div>
              </td>
              <td className="!text-left w-[24%]  hidden lg:table-cell">
                <div className="flex justify-center gap-x-1 items-center">
                  <AccessTimeOutlinedIcon className="!w-4 lg:w-auto dark:text-[#DDD1CE]" />
                  <p className="text-[#312522] text-sm font-light dark:text-[#DDD1CE]">
                    {items?.i_duration_display || items?.i_duration}
                  </p>
                </div>
              </td>
              <td className="!text-left relative ">
                <div className="flex justify-end pr-2 lg:pr-0 lg:justify-center items-center gap-x-2">
                  <div className="hidden lg:block">
                    <PlayCircleFilledWhiteIcon
                      onClick={() => playSong(items, items?.id, items?.id)}
                      className="cursor-pointer dark:!text-[#DDD1CE] "
                    />
                  </div>
                  <div>
                    {!likedTracks?.includes(items?.id) ? (
                      <FavoriteBorderIcon
                        className="hover:text-[#F1B942] cursor-pointer dark:text-[#DDD1CE]"
                        onClick={() => handleLike(items.id)}
                      />
                    ) : (
                      <FavoriteIcon
                        className="text-[#F1B942] cursor-pointer dark:text-[#DDD1CE]"
                        onClick={() => handleLike(items.id)}
                      />
                    )}
                  </div>{" "}
                  <div className={""}>
                    {" "}
                    <MoreVertOutlinedIcon
                      className={`hover:text-[#F1B942] threeDots-icon cursor-pointer dark:text-[#DDD1CE]  `}
                      onClick={() => toggleDropdown(items.id)}
                    />{" "}
                  </div>
                </div>{" "}
                {openDropdownId === items.id && (
                  <div className="relative">
                    {" "}
                    <div
                      className={` threeDots-modal absolute bottom-[85%] right-[25%] z-40 min-w-[200px] text-sm bg-white rounded-lg drop-shadow-lg p-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE] `}
                    >
                      <div className="relative">
                        <p
                          className="my-2 cursor-pointer hover:text-[#F1B942]"
                          onClick={() => setOpen(true)}
                        >
                          <ShareOutlinedIcon className="mx-2" />
                          Share
                        </p>

                        <p
                          onClick={() => {
                            setCreatePlaylistModal((pre) => !pre);
                            getPlaylistData(items?.id, items?.e_platform);
                          }}
                          className="my-2 cursor-pointer hover:text-[#F1B942]"
                        >
                          <QueueMusicOutlinedIcon className="mx-2" />
                          Add to playlist <ArrowRightOutlinedIcon />
                        </p>

                        {createPlaylistModal && (
                          <div className="fixed lg:absolute top-0 right-0 lg:top-[-21px] lg:right-[200px] z-[99999] bg-white  lg:w-[200px] lg:h-[175px] w-full h-dvh  rounded-[4px] dark:border-[1px] dark:border-[#343434]  overflow-scroll  dark:bg-[#151515] dark:text-[#DDD1CE]">
                            <div className="lg:hidden block">
                              <div className="relative ">
                                <ArrowBackIcon
                                  onClick={() => setCreatePlaylistModal(false)}
                                  className="absolute  top-1/2  transform -translate-y-1/2"
                                />
                                <p className="text-center">Add to playlist</p>
                              </div>
                              <div>
                                <Button2 className="!w-[150px] !text-center !mx-auto">
                                  New playlist
                                </Button2>
                              </div>
                            </div>
                            <div className="relative p-2  w-[90%] lg:w-auto">
                              <input
                                type="text"
                                placeholder="Search Playlist"
                                className="bg-[#FAFAF8] rounded-[50px] px-2 py-1 w-full  focus:outline-none focus:border-[#F1B942] text-[#C1C1C1] lg:text-sm text-base font-normal  focus:border-[1px] transition duration-300 dark:bg-[#131313] dark:text-[#A1A1A1] "
                                // value={searchQuery}
                                onChange={handleSearchChange}
                                // onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <SearchOutlinedIcon className="absolute !text-[#312522] !lg:text-base !text-lg top-1/2 transform -translate-y-1/2 right-3 dark:!text-[#A1A1A1]" />
                            </div>
                            <div
                              onClick={() =>
                                handleClickOpenCreatePlaylistTrackModal(
                                  items?.id
                                )
                              }
                              className="cursor-pointer p-2 text-[#312522] hidden lg:block font-semibold text-sm py-2 px-2 dark:text-[#DDD1CE]"
                            >
                              {" "}
                              <AddIcon /> Generate playlist{" "}
                            </div>

                            <div className="h-[80%] overflow-scroll">
                              {filteredPlaylists?.map((playlist, index) => {
                                return (
                                  // <div key={index}  className={` hidden lg:block py-2 px-2 cursor-pointer ${index===0?"lg:border-t-[1px] ":""} lg:border-b-[1px] text-sm font-normal lg:border-t-[#A8A8A8] lg:border-b-[#A8A8A8]`}>
                                  //     <p onClick={()=>handleAddToPlaylist(playlist?.v_code)} className="hidden lg:block">{playlist?.v_name}</p>
                                  // </div>
                                  <div
                                    key={index}
                                    className={` hidden lg:block py-2 px-2 cursor-pointer ${
                                      index === 0 ? " " : ""
                                    }  text-sm font-normal `}
                                  >
                                    <p
                                      onClick={() =>
                                        handleAddToPlaylist(playlist?.v_code)
                                      }
                                      className="hidden lg:block"
                                    >
                                      {playlist?.v_name}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div
          className={`w-full absolute text-center  bg-white dark:bg-[#151515] dark:text-white py-2`}
        >
          "No tracks available"
        </div>
      )}
    </div>
  );
};

export default SharedSong;
