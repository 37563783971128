import React, { useState } from 'react'
import BackToPage from '../common/BacktoPage/BackToPage'
import H220 from '../common/Typograpgy/H220'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import TopDetailCard from '../common/TopDetailCard/TopDetailCard';
import CommonProfileCard from './CommonProfileCard';
import BioComponent from './AboutComponents/BioComponent';
import GenresComponent from './AboutComponents/GenresComponent';
import InstrumentComponent from './AboutComponents/InstrumentComponent';
import DecadesComponent from './AboutComponents/DecadesComponent';
import GeosComponent from './AboutComponents/GeosComponent';
import LanguageComponent from './AboutComponents/LanguageComponent';
import SkillComponent from './AboutComponents/SkillComponent';
import ArtistsComponent from './FavoritesComponents/ArtistsComponent';
import AlbumsComponent from './FavoritesComponents/AlbumsComponent';
import TracksComponent from './FavoritesComponents/TracksComponent';
import PlaylistsComponent from './FavoritesComponents/PlaylistsComponent';
import { useApi } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { profileDetail } from '../../../store/authenticationSlice';


const DisplayProfile = ({nav,setNav,profileData}) => {
    console.log("profileData",profileData);
    const { handleGetRequest,handlePostRequest } = useApi();
  const [showEdit,setShowEdit]=useState(false)


    const dispatch = useDispatch();

    const handleDeletePlaylist = async(id) => {
        const playlistLikePayload = {
            playlist_ids:[id]
        }
        const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
        const profileDataReq = await handleGetRequest("profile-detail", false, true);
        dispatch(profileDetail(profileDataReq?.data));
    }
  return (
    <div>
      {/* <BackToPage pageRoute="/" backTo="Home"/> */}
      <div className="flex justify-start gap-x-2 items-center my-2 px-3 lg:px-0">
        <H220 className="dark:!text-white !capitalize !px-0">
          {profileData?.v_name ? profileData?.v_name : "User Name"}
        </H220>
        <div className="  rounded-3xl bg-[#E9E9E9] dark:bg-[#232323]  flex justify-center pr-2 items-center">
          <PeopleAltIcon className="!text-[#312522] px-2 !text-3xl dark:!text-[#DDD1CE]" />
          <p className="!text-[#312522] dark:!text-[#DDD1CE] text-sm">
            {profileData?.i_share_count ? profileData?.i_share_count : 0}
          </p>
        </div>
        <div className=" rounded-3xl bg-[#E9E9E9] dark:bg-[#232323] dark:text-[#DDD1CE] flex justify-center pr-2 items-center">
          <HeadphonesOutlinedIcon className="!text-[#312522] px-2 !text-3xl dark:!text-[#DDD1CE]" />
          <p className="!text-[#312522] dark:!text-[#DDD1CE] text-sm">
            {profileData?.i_play_count ? profileData?.i_play_count : 0}
          </p>
        </div>
      </div>

      <TopDetailCard
        image={profileData?.t_image_path}
        setShowEdit={setShowEdit}
        titleName={profileData?.v_name || "User Name"}
        duration={profileData?.durationcount}
        artistCount={profileData?.ArtistCount}
        decadeRange={profileData?.DecadeRange}
        playListCount="23"
        genresCount={profileData?.genres?.length}
        platformsSynced={profileData?.PlatformsSynced}
        totalTracks={profileData?.t_song_count}
        pillsData={profileData?.dataCounts}
        Top={profileData?.Top}
        userId={profileData?.id}
        profession={profileData?.v_profession}
      />

      <div className="min-h-[300px] mt-14">
        <div className="grid lg:grid-cols-8 grid-cols-4  content-center text-center  text-[#312522] text-xl font-light gap-x-4 my-4">
          <p
            className={`cursor-pointer lg:text-xl text-sm  ${
              nav === 1 ? "text-[#F1B942]  font-bold" : "dark:text-white"
            }`}
            onClick={() => setNav(1)}
          >
            About me
          </p>
          <p
            className={`cursor-pointer lg:text-xl text-sm  ${
              nav === 2 ? "text-[#F1B942]  font-bold" : "dark:text-white"
            }`}
            onClick={() => setNav(2)}
          >
            Favorites
          </p>
          {/* <p className={`cursor-pointer lg:text-xl text-sm  ${nav===3?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(3)}>Stations</p>
                <p className={`cursor-pointer lg:text-xl text-sm  ${nav===4?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(4)}>Performance</p> */}
        </div>
        <div className="w-full h-[5px] bg-[#D9D9D9] grid  lg:grid-cols-8 grid-cols-4 ">
          <div className={`${nav === 1 ? "bg-[#F1B942]" : ""}`}></div>
          <div className={`${nav === 2 ? "bg-[#F1B942]" : ""}`}></div>
          {/* <div className={`${nav===3?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===4?"bg-[#F1B942]":""}`}></div> */}
        </div>

        {nav === 1 && (
          <div>
            <CommonProfileCard name={profileData?.v_name}>
              <BioComponent
                showEdit={showEdit}
                profession={profileData?.v_profession}
                t_bio={profileData?.t_bio}
              />
            </CommonProfileCard>
            <div className="lg:grid lg:grid-cols-2 gap-x-5">
              {profileData?.Favourite?.genres?.length > 0 && (
                <CommonProfileCard name="Genres">
                  <GenresComponent
                    showEdit={showEdit}
                    genresList={profileData?.Favourite?.genres}
                    nav={nav}
                    flag="about"
                  />
                </CommonProfileCard>
              )}

              {profileData?.Favourite?.musicInstruments?.length > 0 && (
                <CommonProfileCard name="Favorite instruments">
                  <InstrumentComponent
                    flag="about"
                    showEdit={showEdit}
                    nav={nav}
                    genresList={profileData?.Favourite?.musicInstruments}
                  />
                </CommonProfileCard>
              )}
            </div>

            {profileData?.Favourite?.decades?.length > 0 && (
              <CommonProfileCard name="Decades">
                <DecadesComponent
                  decades={profileData?.Favourite?.decades}
                  showEdit={showEdit}
                  nav={nav}
                />
              </CommonProfileCard>
            )}

            {profileData?.Favourite?.geos?.length > 0 && (
              <CommonProfileCard name="Geos">
                <GeosComponent
                  selectedWorldMapCountry={
                    profileData?.Favourite?.geo_locations
                  }
                  nav={nav}
                  showEdit={showEdit}
                />
              </CommonProfileCard>
            )}
            <div className="grid lg:grid-cols-2 gap-x-5">
              {profileData?.Favourite?.languages?.length > 0 && (
                <CommonProfileCard name="Favorite languages">
                  <LanguageComponent
                    languageList={profileData?.Favourite?.languages}
                    showEdit={showEdit}
                  />
                </CommonProfileCard>
              )}
              {/* <CommonProfileCard name="Selected  instrument and skill level"><SkillComponent/></CommonProfileCard> */}
            </div>
          </div>
        )}

        {nav === 2 && (
          <div>
            {profileData?.Favourite?.genres?.length > 0 && (
              <CommonProfileCard name="Genres">
                <GenresComponent
                  genresList={profileData?.Favourite?.genres}
                  nav={nav}
                  handleDeletePlaylist={handleDeletePlaylist}
                  showEdit={showEdit}
                  flag="profilePage"
                />
              </CommonProfileCard>
            )}

            {profileData?.Favourite?.decades?.length > 0 && (
              <CommonProfileCard name="Decades">
                <DecadesComponent
                  handleDeletePlaylist={handleDeletePlaylist}
                  decades={profileData?.Favourite?.decades}
                  nav={nav}
                  showEdit={showEdit}
                />
              </CommonProfileCard>
            )}

            {profileData?.Favourite?.geos?.length > 0 && (
              <CommonProfileCard name="Geos">
                <GeosComponent
                  selectedWorldMapCountry={profileData?.Favourite?.geos}
                  nav={nav}
                  handleDeletePlaylist={handleDeletePlaylist}
                  showEdit={showEdit}
                />
              </CommonProfileCard>
            )}

            <div className="grid lg:grid-cols-2 gap-x-5">
              {profileData?.Favourite?.artists?.length > 0 && (
                <CommonProfileCard name="Artists">
                  <ArtistsComponent
                    handleDeletePlaylist={handleDeletePlaylist}
                    albumList={profileData?.Favourite?.artists}
                    showEdit={showEdit}
                  />
                </CommonProfileCard>
              )}

              {profileData?.Favourite?.albums?.length > 0 && (
                <CommonProfileCard name="Albums">
                  <AlbumsComponent
                    handleDeletePlaylist={handleDeletePlaylist}
                    albumList={profileData?.Favourite?.albums}
                    showEdit={showEdit}
                  />
                </CommonProfileCard>
              )}
            </div>
            {profileData?.Favourite?.songs?.length > 0 && (
              <CommonProfileCard name="Tracks">
                <TracksComponent albumList={profileData?.Favourite?.songs} />
              </CommonProfileCard>
            )}

            {profileData?.Favourite?.playlists?.length > 0 && (
              <CommonProfileCard name="Playlists">
                <PlaylistsComponent
                  handleDeletePlaylist={handleDeletePlaylist}
                  albumList={profileData?.Favourite?.playlists}
                  showEdit={showEdit}
                />
              </CommonProfileCard>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DisplayProfile