import React, { useState } from "react";
import GenresSwiper from "../GenresSwiper";
import CommonListing from "../../collectionPagesComponents/CommonListing";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import GenresUpdate from "./GenresUpdateComponent";

const GenresComponent = ({ genresList,showEdit, flag, nav, handleDeletePlaylist }) => {
  const [openGenresModal, setOpenGenresModal] = useState(false);

  console.log("genresList", genresList);
  return (
    <div className="dark:text-[#DDD1CE]">
      <GenresUpdate
        openGenresModal={openGenresModal}
        setOpenGenresModal={setOpenGenresModal}
      />
      {showEdit && (
        <div className={`absolute top-2 right-0 min-w-10  block`}>
          <button className=" w-5 h-5">
            <BorderColorOutlinedIcon
              onClick={() => setOpenGenresModal(true)}
              className="!text-[#312522] dark:!text-[#A8A8A8] hover:!text-[#F1B942]"
            />
          </button>
        </div>
      )}
      {nav === 1 ? (
        <GenresSwiper genresList={genresList} flag={flag} />
      ) : (
        <CommonListing
          listing={genresList}
          title="genres"
          flag="profilepagelisting"
          hidetitle={true}
          fromprofile="/collection/genres"
          handleDeletePlaylist={handleDeletePlaylist}
        />
      )}
    </div>
  );
};

export default GenresComponent;
