import { Link, useLocation, useParams } from "react-router-dom";
import DetailTopSection from "./DetailTopSection";
import Tracks from "../common/TracksComponents/Tracks";
import DetailBottomSection from "./DetailBottomSection";
import { useState } from "react";

function CommonDetail({
  details,
  setSongDeleteId,
  title,
  detailtitles,
  setType,
  setSort,
  sort,
  type,
  setSearchQuery,
  fetchPlaylistData,
  Id,
  searchQuery,
  setSongId,
  setIsliked,
  setIsFilterApplied,
  setFilter,
  Like,
  refreshPlaylist,
  setPlaylistDetailData,
}) {
  const location = useLocation();
  console.log("detailssss", details);
  const { id } = useParams();
  const parts = location.pathname.split("/");
  const [managePlaylistData, setManagePlaylistData] = useState();
  const [page, setpage] = useState(1);

  return (
    <div className="">
      <p className="text-[#312522] text-xl font-semibold my-6 px-2 lg:px-0 dark:text-white">
        {details?.t_name}
      </p>
      <DetailTopSection
        Id={Id}
        title={title}
        isPrivate={details.is_private}
        details={details}
        tracksLength={details?.TrackCount}
        image={details?.t_image || details?.t_image_path}
        detailtitles={detailtitles}
        name={details?.t_name}
        time={details?.TotalDuration}
        decades={details?.DecadeRange}
        AlbumCount={details?.AlbumCount}
        ArtistCount={details?.ArtistCount}
        RegionCount={details?.RegionCount}
        platform={details?.e_platform}
        platformName={details?.e_platform}
        Like={Like}
        t_playlist_id={details?.t_playlist_id}
        is_synced={details?.is_synced}
        is_global={details?.is_global}
        refreshPlaylist={refreshPlaylist}
        setManagePlaylistData={setManagePlaylistData}
        type={type}
        setPlaylistDetailData={setPlaylistDetailData}
        t_description={details?.t_description}
      />

      {title !== "Artists" && (
        <Tracks
          title={title}
          filterId={details?.id}
          scope={parts[parts.length - 2]}
          sort={sort}
          setIsFilterApplied={setIsFilterApplied}
          setFilter={setFilter}
          setSongDeleteId={setSongDeleteId}
          setSort={setSort}
          type={type}
          setType={setType}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          setIsliked={setIsliked}
          setSongId={setSongId}
          setpage={setpage}
          page={page}
        />
      )}
      {/* {(title==="Albums" || title==="Decades" || title==="Geos")&&<TracksTable
                tracks={songDetails}
                Tracks_setType={setType}
                Tracks_setSort={setSort}
                Tracks_setSearchString={setSearchString}
                reloadTracks={fetchPlaylistData}
                filter={false}
                showSearchBar={true}
                 page={`${title}Page`}
            />} */}
      {title === "Artists" && (
        <DetailBottomSection
          filterId={details?.id}
          scope={parts[parts.length - 2]}
          setIsFilterApplied={setIsFilterApplied}
          setFilter={setFilter}
          name={details?.t_name}
          bio={details?.Aboutus}
          sort={sort}
          setSongDeleteId={setSongDeleteId}
          setSort={setSort}
          type={type}
          setType={setType}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          setIsliked={setIsliked}
          setSongId={setSongId}
          fetchPlaylistData={fetchPlaylistData}
          details={details?.Albums}
          setpage={setpage}
          page={page}
        />
      )}
    </div>
  );
}

export default CommonDetail;
