import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Button2 from "../common/GlobalButtonLayout/Button2";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect, useState } from "react";

const PricingCards = ({
  data,
  title,
  subtitle,
  className,
  buttonColor,
  featureList,
  isactive,
  bgColor,
  vcode,
  pdf,
  deactivatePlan,
  isSubscription,
  expireDate,
  discountPrice,
  upgradePlan,
  downgradePlan,
  activatedPrice,
}) => {
  console.log("isactive", isactive);
  console.log(expireDate, "title");

  const TodayDate = new Date();
  const formattedDate = TodayDate.toISOString().split("T")[0];
  const isExpired = expireDate < formattedDate;
  const [downgrade, setDowngrade] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  console.log(downgrade, "down");
  console.log(upgrade, "upgrade");
  useEffect(() => {
    if (data) {
      const check = data?.map((i) => {
        if (i.v_discounted_price < discountPrice) {
          if (i.v_discounted_price > 0) {
            setDowngrade(true);
          }
          return;
        } else if (i.v_discounted_price > discountPrice) {
          setUpgrade(true);
          return;
        }
      });
    }
  }, [data]);

  const handleDownloadPdf = () => {
    // Download PDF logic here
    if (pdf) {
      window.open(pdf, "_blank");
    } else {
      console.error("PDF URL is not available.");
    }
  };
  return (
    <>
      <div
        className={`rounded-lg py-5  my-3 text-black  ${className}`}
        style={{ backgroundColor: `${bgColor}` }}
      >
        <p
          className={`text-center ${
            isSubscription
              ? "text-3xl lg:text-[46px]font-semibold capitalize mt-4 "
              : ""
          }`}
        >
          {title}
        </p>

        {subtitle == 0 ? (
          <p
            className={`lg:my-4 my-2 !bg-white text-center  !bg-opacity-30 py-2 
                       
                            text-base  font-semibold capitalize 
                         
                        `}
          >
            Free
          </p>
        ) : (
          <p
            className={`lg:my-4 my-2 !bg-white text-center !bg-opacity-30 py-2 ${
              isSubscription
                ? "text-base lg:text-3xl font-semibold capitalize "
                : ""
            }`}
          >
            $ {discountPrice} / Month
          </p>
        )}

        {/* <ul className="my-2">
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
            </ul> */}
        <div
          className={` px-8 ${
            isSubscription
              ? "text-xs flex flex-col    lg:text-sm font-normal capitalize "
              : ""
          }`}
        >
          {parse(featureList)}
        </div>
        <div className="my-5 text-center">
          {!isSubscription && subtitle != 0 && (
            <Link
              to={`${!isactive ? `/payment/${vcode}` : `#`}`}
              className={`min-w-[150px] my-3 px-3 py-2 text-white  rounded-[50px] ${buttonColor} !bg-[#1F2121]`}
            >
              {isactive
                ? "Activated"
                : subtitle > activatedPrice
                ? "Upgrade"
                : "Buy Now"}
            </Link>
          )}
          {isSubscription && (
            <Link
              to={`#`}
              className={`min-w-[150px] my-3 px-3 py-2 text-white  rounded-[50px] ${buttonColor} !bg-[#1F2121]`}
            >
              Activated
            </Link>
          )}
        </div>
        {isactive && isSubscription && (
          <p
            className={`text-center ${
              isSubscription ? "text-sm lg:text-lg font-normal capitalize " : ""
            }`}
          >
            {isExpired
              ? "Your Premium Plan expired"
              : `Expires on : ${expireDate}`}
          </p>
        )}
        {isSubscription && (
          <p
            className={`cursor-pointer my-3 text-center  ${
              isSubscription ? "text-sm lg:text-lg font-normal  " : ""
            }`}
            onClick={() => handleDownloadPdf()}
          >
            <DownloadIcon /> Downloads receipt
          </p>
        )}
      </div>
      {isSubscription && (
        <div className="flex mt-4 justify-center  items-center gap-x-2 px-3 lg:gap-x-4 ">
          {upgrade && (
            <Button2
              onClick={() => upgradePlan()}
              className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#FFAD00] !bg-[#1F2121] text-xs lg:text-base !text-center  lg:!w-40 lg:!h-12"
            >
              Upgrade
            </Button2>
          )}
          {downgrade && subtitle != 0 && (
            <Button2
              onClick={() => downgradePlan()}
              className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#796e57] text-xs lg:text-base !text-center lg:!w-40 lg:!h-12"
            >
              Downgrade
            </Button2>
          )}
          <Button2
            onClick={() => deactivatePlan(vcode)}
            className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#FFAD00] text-xs lg:text-base !text-center lg:!w-40 lg:!h-12"
          >
            Deactivate
          </Button2>
        </div>
      )}
    </>
  );
};

export default PricingCards;

// onclick={()=>deactivatePlan(vcode)}
