import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import H324 from "../../common/Typograpgy/H324";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useApi } from "../../../../api/api";
import Button1 from "../../common/GlobalButtonLayout/Button1";
import { useDispatch, useSelector } from "react-redux";
import { aboutDetail } from "../../../../store/authenticationSlice";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const BioUpdate = ({ openBioModal, setOpenBioModal, t_bio }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const profileData = useSelector((state) => state.auth.profileData);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDark = useSelector((state) => state.dark.isDarkValue);
  console.log(isDark, "isDark");
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#312522" : "#F1B942",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: isDark ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const textRef = useRef(null);

  const handleRadioChange = (event) => {
    console.log("ulllsdcs", event.target.value);
    setIsMusicProfessional(event.target.value === "yes");
    if (event.target.value === "no") {
      setProfessionalType({
        artist: false,
        curator: false,
        producer: false,
        educator: false,
        other: false,
      });
    }
  };

  const [isMusicProfessional, setIsMusicProfessional] = useState(true);
  //   console.log(profileData,"profileDATA");
  const [professionalType, setProfessionalType] = useState({
    artist: false,
    curator: false,
    producer: false,
    educator: false,
    other: false,
  });

  const [inputValue, setInputValue] = useState("");
  const [tBio, setTBio] = useState(t_bio);
  useEffect(() => {
    if (profileData.v_profession) {
      let newProfessionalType = { ...professionalType };
      let unmatchedProfessions = "";

      profileData.v_profession.forEach((element) => {
        if (element in professionalType) {
          newProfessionalType[element] = true;
        } else {
          unmatchedProfessions = element;
        }
      });

      if (unmatchedProfessions != "") {
        newProfessionalType.other = true;
        setInputValue(unmatchedProfessions);
      }
      setProfessionalType(newProfessionalType);
    } else {
      setIsMusicProfessional(false);
    }
    setTBio(t_bio);
  }, [profileData, t_bio]);

  console.log(professionalType, "rpr");

  const handleInputChange = (e) => {
    console.log("e.target.value", e.target.value);

    setInputValue(e.target.value);
    // setUll(e.target.value);
  };
  console.log(professionalType, "professional Type");
  const [pError, setPError] = useState(false);

  const [oError, setOError] = useState(false);
  const { handlePostRequest, handleGetRequest } = useApi();
  const handleCloseCreatePlaylistTrackModal = () => {
    setOpenBioModal(false);
  };
  useEffect(() => {
    if (textRef.current) {
      textRef.current.focus();
      const len = textRef.current.value.length;
      textRef.current.setSelectionRange(len, len);
    }
  }, [openBioModal, inputValue]);
  const handleTxtChange = (e) => {
    setTBio(e.target.value);
  };
  const handleChange = (event, para) => {
    console.log("vvcpara", para, event);
    // para==="notification" && setNotificationEnabled(event.target.checked);
    // para==="communitynotification" && setCommunityEnabled(event.target.checked);
    // para==="dark" &&  setDarkThemeEnabled(event.target.checked)
    // para==="privacy" &&  setPrivacyEnabled(event.target.checked)
    setProfessionalType({
      ...professionalType,
      [event.target.name]: event.target.checked,
    });
    setPError(false);
  };
  const onSubmit = async () => {
    if (professionalType.other == true && inputValue == "") {
      setOError(true);
      return;
    }

    const switchTrue = Object.values(professionalType).some(
      (value) => value === true
    )? false
      : true;
    console.log(switchTrue, "hd");
    if (isMusicProfessional === true && switchTrue === true) {
      setPError(true);
      return;
    }
    console.log(switchTrue, "switchTru");

    const bioUpdatePayload = {
      status: "bio",
      txtbio: tBio,
      v_profession: Object.entries(professionalType)
        .filter(([key, value]) => value === true)
        .map(([key]) => (key === "other" && inputValue ? inputValue : key)),
    };
    console.log(bioUpdatePayload, "bioUpdate");
    const generatePlaylistRes = await handlePostRequest(
      `profile-edit-bio`,
      bioUpdatePayload,
      true,
      true
    );
    dispatch(aboutDetail(bioUpdatePayload));

    setOpenBioModal(false);
  };

  return (
    <React.Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={openBioModal}
        maxWidth={"lg"}
        // onClose={handleCloseCreatePlaylistTrackModal}
        aria-labelledby="responsive-dialog-title"
        className={`lg:w-screen ${isDark ? "dark" : ""} `}
      >
        <div className="dark:bg-[#0D0D0D] py-3 lg:py-3 px-3 lg:px-8 lg:min-w-[920px] lg:max-w-[1120px]    h-[100%] lg:h-auto dark:text-[#FFFFFF]">
          <div className="flex flex-col ">
            <div className="flex w-full justify-between mb-2 items-center">
              {" "}
              <H324 className="dark:!text-[#FFFFFF]">
                {profileData?.v_name}'s bio :-
              </H324>
              <CancelOutlinedIcon
                onClick={() => handleCloseCreatePlaylistTrackModal()}
                className=" cursor-pointer dark:!text-[#A8A8A8] "
              />
            </div>
            <textarea
              className="w-full resize-none leading-4 dark:bg-[#0D0D0D] mb-5 dark:text-white text-[14px] h-full "
              type="text"
              maxLength={300}
              rows={4}
              // ref={textRef}
              value={tBio}
              onChange={handleTxtChange}
            >
              {" "}
            </textarea>
          </div>
          <h2 className="text-[16px] lg:text-xl font-medium mb-3 dark:text-white">
            Are you a music professional?
          </h2>
          <div className="mb-4">
            <label className="inline-flex items-center mr-6">
              <input
                type="radio"
                className="form-radio appearance-none w-4 h-4 border-[2px] border-[#D9D9D9] checked:bg-[#F1B942] rounded-full"
                name="musicProfessional"
                value="yes"
                checked={isMusicProfessional}
                onChange={handleRadioChange}
              />
              <span className="ml-2 dark:text-[#D9D9D9]">Yes</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio form-radio appearance-none w-4 h-4 border-[2px] border-[#D9D9D9] checked:bg-[#F1B942] rounded-full"
                name="musicProfessional"
                value="no"
                checked={!isMusicProfessional}
                onChange={handleRadioChange}
              />
              <span className="ml-2 dark:text-[#D9D9D9]">No</span>
            </label>
          </div>
          {isMusicProfessional && (
            <div className="mb-4">
              {pError && (
                <p className="text-red-500">
                  {" "}
                  Please Select Music professional
                </p>
              )}
              <div className="grid grid-cols-2 gap-5">
                {Object.keys(professionalType).map((type) => (
                  <div
                    key={type}
                    className="mr-4 mb-2 flex justify-between items-center h-10"
                  >
                    <span class="ms-3 text-sm mx-2 font-medium text-gray-900 dark:text-gray-300">
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </span>

                    <AntSwitch
                      checked={professionalType[type]}
                      name={type}
                      onChange={(e) => handleChange(e, type)}
                      className="dark:accent-slate-400"
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {professionalType?.other && (
            <div className="flex items-center">
              <input
                type="text"
                className={`w-56 py-2 px-2 mr-4 rounded-2xl border-[1px] dark:bg-[#0D0D0D] dark:border-white dark:text-white ${
                  oError ? "border-red-500" : "border-black"
                } `}
                value={inputValue}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          )}
          <div className="flex justify-center items-center mt-[50px] lg:mt-[20px]">
            <div className="w-[104px]">
              <Button1
                type="submit"
                onClick={onSubmit}
                className=" mx-auto text-center text-sm ml-auto dark:hover:!text-[#F3C25A]"
              >
                Save
              </Button1>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default BioUpdate;
