import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import LanguageUpdateComponent from "./LanguageUpdateComponent";

const LanguageComponent = ({ languageList, showEdit }) => {
  const [openLangModal, setOpenLangModal] = useState(false);

  return (
    <div className="dark:text-[#DDD1CE] flex justify-start items-center gap-7">
      <LanguageUpdateComponent
        openLangModal={openLangModal}
        setOpenLangModal={setOpenLangModal}
      />
      {showEdit && (
        <div className={`absolute top-2 right-0 min-w-10  block`}>
          <button className=" w-5 h-5">
            <BorderColorOutlinedIcon
              onClick={() => setOpenLangModal(true)}
              className="!text-[#312522] dark:!text-[#A8A8A8] hover:!text-[#F1B942]"
            />
          </button>
        </div>
      )}
      {languageList?.map((items, index) => {
        return (
          <div>
            <div className="w-10 h-10 rounded-full my-2 mx-auto">
              <img
                src={items?.image}
                alt=""
                className="object-cover w-full h-full rounded-full mx-auto"
              />
            </div>
            <p>{items?.display_name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default LanguageComponent;
