import { useEffect, useState } from "react";
import { useApi } from "../../api/api";
import PricingCards from "../../view/components/PremiumComponents/PricingCards";
import Button2 from "../../view/components/common/GlobalButtonLayout/Button2";
import H130 from "../../view/components/common/Typograpgy/H130";
import H220 from "../../view/components/common/Typograpgy/H220";
import P16 from "../../view/components/common/Typograpgy/P16";
import P20 from "../../view/components/common/Typograpgy/P20";
import { Link } from "react-router-dom";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";

const Premium = () => {
  const { handleGetRequest, handlePostRequest } = useApi();
  const [SubscriptionData, setSubscriptionData] = useState();

  useEffect(() => {
    // Skip the first render
    (async () => {
      const subscriptionRes = await handleGetRequest(
        `subscriptions`,
        false,
        true
      );
      if (subscriptionRes?.flag) {
        console.log("subscriptionRes", subscriptionRes);
        setSubscriptionData(subscriptionRes?.data);
      }
    })();
  }, []);

  console.log("SubscriptionData", SubscriptionData);
  const activePlan = SubscriptionData?.find(
    (plan) => plan?.e_status === "Active"
  );
  const activePlanPrice = activePlan ? activePlan?.v_plan_price : undefined;
  return (
    <>
      <div className="">
        {/* <BackToPage pageRoute="/" backTo="Home"/> */}
        <div className="px-3 lg:px-0">
          <H220 className="!my-3 ">Premium</H220>
        </div>
        <div className="lg:grid lg:grid-cols-12  gap-5 bg-[#F1F1F1] rounded-lg my-3 dark:bg-[#1B1B1B]">
          <div className="col-span-9  w-full px-3 py-5 dark:!text-[#DDD1CE]">
            <H130 className="dark:!text-[#DDD1CE] !text-xl">
              Unlock The Full Potential Of Your Music Experience
            </H130>
            <P16 className="my-7 dark:!text-[#DDD1CE]">
           Explore our subscription plans and find the perfect fit for your music journey. enjoy enhanced features, exclusive content, and more!
            </P16>
            <Link
              to="/subscription"
              className="border-[3px] !bg-black !text-white   mt-5 border-black rounded-[50px] py-2 px-3 !w-[60%] lg:!w-[30%] hover:!bg-white hover:!text-black dark:border-[#DDD1CE]"
            >
              View subscription
            </Link>
          </div>
          <div className="col-span-3 flex justify-center items-center">
            <img
              className="dark:filter dark:invert"
              src="/images/premium/headphone-img.svg"
              alt=""
            />
          </div>
        </div>

        <div className="drop-shadow-xl rounded-lg w-full bg-white my-2 py-10 px-8 dark:bg-[#151515]">
          <P20 className="text-center dark:text-[#DDD1CE] font-semibold text-xl lg:text-xl">
            Upgrade to unlock paid features
          </P20>
          <div className="lg:grid lg:grid-cols-3 place-items-stretch   gap-x-4">
          
          
          
            {SubscriptionData?.map((item) => {
              return (
                <>
                  <PricingCards
                    vcode={item?.v_code}
                    title={item?.v_plan_name}
                    subtitle={item?.v_plan_price}
                    discountPrice={item?.v_discounted_price}
                    buttonColor="bg-[#CDCDCD]"
                    className="bg-[#F1F1F1]"
                    featureList={item?.t_features}
                    bgColor={item?.v_color_code}
                    activatedPrice={activePlanPrice}
                    isactive={item?.is_subscription_active}
                  />
                </>
              );
            })}
            {/* <PricingCards title="Basic" subtitle="Free" buttonColor="bg-[#CDCDCD]" className="bg-[#F1F1F1]"/>
                <PricingCards title="Gold" subtitle="$ 09 / Month" buttonColor="bg-[#C19435]" className="bg-[#F1B942] text-white"/>
                <PricingCards title="Platinum" subtitle="$ 19 / Month" buttonColor="bg-[#A44E3B]" className="bg-[#EA6F54] text-white"/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Premium;
