import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useApi } from "../../../../api/api";
import Button1 from "../../common/GlobalButtonLayout/Button1";
import { useDispatch, useSelector } from "react-redux";
import { aboutDetail } from "../../../../store/authenticationSlice";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import globalToast from "../../../../hooks/globalToast";

const LanguageUpdateComponent = ({ openLangModal, setOpenLangModal }) => {
  const profileData = useSelector((state) => state.auth.profileData);

  const [selectedLanguages, setSelectedLanguages] = useState(
    profileData.Favourite.languages.map((item) => item.id)
  );

  const [musicLanguageList, setMusicLanguageList] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDark = useSelector((state) => state.dark.isDarkValue);
  const { handlePostRequest } = useApi();
  const handleCloseCreatePlaylistTrackModal = () => {
    setOpenLangModal(false);
  };

  useEffect(() => {
    (async () => {
      const languagesPlayload = {
        type: "languages",
        current_page: 1,
        per_page: 10000,
      };
      const languagesStepperRes = await handlePostRequest(
        `users/music-preferences/get`,
        languagesPlayload,
        false,
        true
      );
      console.log("languagesStepperRes", languagesStepperRes?.data?.data);

      if (languagesStepperRes?.flag) {
        setMusicLanguageList(languagesStepperRes?.data?.data);
      }
    })();
  }, [profileData]);

  const onSubmit = async () => {


    if (selectedLanguages.length === 0) {
          globalToast(
            "Please select your preferred music-listening languages to continue"
          );
      return;
    }



    const instruPayload = {
      scope: "language",
      ids: selectedLanguages,
    };

    const setlng = {
      status: "language",
      language: musicLanguageList.filter((e) =>
        selectedLanguages.includes(e.id)
      ),
    };
    const generatePlaylistRes = await handlePostRequest(
      `profile-edit-preferences`,
      instruPayload,
      true,
      true
    );
    dispatch(aboutDetail(setlng));
    setOpenLangModal(false);
  };

  const handleSelectedLanguages = (id) => {
    setSelectedLanguages((prev) => {
      const isItemAlreadySelected = prev.includes(id);

      if (isItemAlreadySelected) {
        // If item is already selected, remove it from the array
        return prev.filter((item) => item !== id);
      } else {
        // If item is not selected, add it to the array
        return [...prev, id];
      }
    });
  };

  return (
    <React.Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={openLangModal}
        maxWidth={"lg"}
        // onClose={handleCloseCreatePlaylistTrackModal}
        aria-labelledby="responsive-dialog-title"
        className={`lg:w-screen ${isDark ? "dark" : ""} `}
      >
        <div className="dark:bg-[#0D0D0D]  px-3 py-1 lg:py-3 lg:px-8 min-w-[300px] lg:min-w-[500px]    h-[100%] lg:h-auto dark:text-[#FFFFFF]">
          <div className="flex flex-col rounded-xl ">
            <div className="flex w-full justify-between mb-2 items-center">
              {" "}
              <h6 className="text-black text-center text-[16px] lg:text-[24px] dark:text-white font-medium  my-3">
                Please select language ?
              </h6>
              <CancelOutlinedIcon
                onClick={() => handleCloseCreatePlaylistTrackModal()}
                className=" cursor-pointer dark:!text-[#A8A8A8] "
              />
            </div>

            <div className="lg:px-10 px-3 mt-2 flex flex-col justify-center lg:my-auto">
              <div
                className=" "
                style={{
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  margin: "auto",
                }}
              >
                {musicLanguageList?.map((language, index) => {
                  return (
                    <div
                      key={language?.id}
                      className="language-img-wrap "
                      onClick={() => handleSelectedLanguages(language.id)}
                    >
                      <div className="relative w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] rounded-full">
                        <img
                          src={language?.image}
                          alt={language.prefix}
                          className=" rounded-full w-full h-full mx-auto"
                        ></img>
                        {selectedLanguages.includes(language.id) && (
                          <div className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] bg-[#00000063] absolute top-0 rounded-full flex justify-center items-center">
                            <img
                              src="/Vector.png"
                              alt="checkmark"
                              className=""
                            />
                          </div>
                        )}
                      </div>
                      <button className="language-btn">
                        <span className="dark:text-white">{language.name}</span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-center items-center mt-[50px] lg:mt-[20px]">
              <div className="w-[104px]">
                <Button1
                  type="submit"
                  onClick={onSubmit}
                  className=" mx-auto text-center ml-auto dark:hover:!text-[#F3C25A]"
                >
                  Save
                </Button1>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default LanguageUpdateComponent;
