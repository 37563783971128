const H324 = ({ children, className }) => {
  return (
    <h3
      className={`text-[#312522] text-[16px] lg:text-2xl font-semibold ${className}`}
    >
      {children}
    </h3>
  );
};
export default H324;
