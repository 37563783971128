import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useApi } from "../../../../api/api";
import Button1 from "../../common/GlobalButtonLayout/Button1";
import { useDispatch, useSelector } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { aboutDetail } from "../../../../store/authenticationSlice";
import globalToast from "../../../../hooks/globalToast";

const InstrumentUpdate = ({ openInstruModal, setOpenInstruModal }) => {
  const profileData = useSelector((state) => state.auth.profileData);
  const [selectedItems, setSelectedItems] = useState(
    profileData.Favourite.musicInstruments.map((item) => item.id)
  );
  console.log(selectedItems, "hexsllo");

  const [instruments, setInstruMents] = useState([]);
  console.log(instruments, "s");
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDark = useSelector((state) => state.dark.isDarkValue);
  const { handlePostRequest, handleGetRequest } = useApi();
  const handleCloseCreatePlaylistTrackModal = () => {
    setOpenInstruModal(false);
  };
  // const instruments = [
  //   { id: 2, src: "/guitar-instrument1.png", alt: "guitar-instrument1" },
  //   { id: 5, src: "/drum.png", alt: "drum" },
  //   { id: 3, src: "/piano.png", alt: "piano" },
  //   { id: 4, src: "/violin.png", alt: "violin" },
  // ];
  const handleSelect = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id]
    );
  };

  useEffect(() => {
    (async () => {
      const decadesStepperRes = await handleGetRequest(
        `studio/get-instruments
`,
        // decadesPlayload,
        false,
        true
      );

      if (decadesStepperRes?.flag) {
        setInstruMents(decadesStepperRes?.data);
      }
      console.log(decadesStepperRes, "decades ");
    })();

    setSelectedItems(
      profileData.Favourite.musicInstruments.map((item) => item.id)
    );
  }, [profileData]);

  const onSubmit = async () => {
    console.log(selectedItems.length, "ints");
    if (selectedItems.length === 0) {
      globalToast("Select your music instrument", true);
      return;
    }
    const instruPayload = {
      scope: "music_instrument",
      ids: selectedItems,
    };

    const setlng = {
      status: "instrument",
      musicInstruments: instruments.filter((e) => selectedItems.includes(e.id)),
    };
    const generatePlaylistRes = await handlePostRequest(
      `profile-edit-preferences`,
      instruPayload,
      true,
      true
    );
    setOpenInstruModal(false);
    dispatch(aboutDetail(setlng));
  };

  return (
    <React.Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={openInstruModal}
        // onClose={handleCloseCreatePlaylistTrackModal}
        aria-labelledby="responsive-dialog-title"
        className={`lg:w-screen ${isDark ? "dark" : ""} `}
      >
        <div className="dark:bg-[#0D0D0D] py-3 px-3 lg:px-8 lg:min-w-[500px]    h-[100%] lg:h-auto dark:text-[#FFFFFF]">
          <div className="flex flex-col ">
            <div className="flex w-full justify-between mb-2 gap-2  items-center">
              {" "}
              <h2 className="text-[16px] lg:text-[24px] font-medium  dark:text-white">
                What are your favorite Instruments ?
              </h2>
              <CancelOutlinedIcon
                onClick={() => handleCloseCreatePlaylistTrackModal()}
                className=" cursor-pointer dark:!text-[#A8A8A8] "
              />
            </div>

            <div>
              <div className="flex justify-center items-center gap-x-3 lg:gap-x-10 my-10">
                {instruments.map((instrument) => (
                  <div
                    key={instrument.id}
                    className={`lg:w-20 lg:h-20 w-16 h-16 border-[1px] flex justify-center items-center rounded-full cursor-pointer ${
                      selectedItems.includes(instrument.id)
                        ? "bg-[#F1B942] border-[#F1B942]"
                        : "bg-transparent border-[#F1B942]"
                    }`}
                    onClick={() => handleSelect(instrument.id)}
                  >
                    <img
                      src={instrument.image}
                      alt={instrument.name}
                      className="dark:fliter w-12 lg:w-14  dark:invert"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-center items-center mt-[20px] lg:mt-[20px]">
              <div className="w-[104px]">
                <Button1
                  type="submit"
                  onClick={onSubmit}
                  className=" mx-auto text-center ml-auto dark:hover:!text-[#F3C25A]"
                >
                  Save
                </Button1>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default InstrumentUpdate;
