// import { useState } from "react"
// import { Link, useLocation, useParams } from "react-router-dom"
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import ShareModal from "./ShareModal";
// import { handleOpenModal } from "../../../store/modalSlice";
// import { useDispatch } from "react-redux";
// import { handleShareModal } from "../../../store/shareModalGlobalSlice";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';

// function CommonListing({listing,title,className,imgHeight,prop}){
//     const dispatch = useDispatch()
//     const location = useLocation()
//     const { id } = useParams();
//     const [isOptions, setIsOptions] = useState(null)
//     const [link, setLink] = useState(true);
//     const [ids, setId] = useState("");
//     const handleClickOpen = (id,idss) => {
//         console.log("idss",idss);
//             localStorage.setItem('itemId', idss);
//            dispatch(handleShareModal(`https://curioushit-frontend.nevinainfotech.in${prop==="Artists"?'/collection/albums':location.pathname}/${id}`))

//     };

//     return(
//         <div className=" relative px-2 lg:px-0">
//             <ShareModal  id={ids} location={location}/>
//            { (title!=="playlists" || title!=="artists") && <h2 className={`text-[#312522] ${location.pathname==="/global-search"?"hidden":""} my-5 font-semibold text-xl dark:text-white`} >{id?"":title}</h2>}
//            {listing?.length<1?
//             <div>
//             <div className="py-16 bg-white drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
//             <p className="lg:text-xl text-lg font-semibold">
//                     No playlist Found
//             </p>
//             </div>
//             </div>
//            :
//            <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mt-2 !my-7 ${className}`}>
//                 {listing?.map((item)=>{
//                     return(
//                         // <Link  to={link ? `/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}` : '#'}
//                         <div  key={item?.id} className="rounded-md  cursor-pointer ">
//                             <div className={` w-full  h-40  overflow-hidden rounded-xl relative flex justify-center items-center ${title==="Geos"?"bg-white":""} ${imgHeight}`} style={{backgroundColor:item?.v_color_code}} onMouseEnter={()=>setIsOptions(item.id)} onMouseLeave={()=>setIsOptions(null)}>

//                              <div className="absolute top-0 border-t-transparent right-[4%] border-[2px] flex justify-center items-center text-center  h-7  rounded-b-3xl border-white p-2">
//                                 <div className="text-[10px] text-white">
//                                 <p>{item?.v_total_songs}</p>
//                                 </div>
//                             </div>

//                             <Link  to={`/collection/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}?id=${item?.id}&globalid=${item?.is_global}`} className={`h-40  w-full ${title==="Geos" || title==="Decades" ? "flex flex-col justify-center items-center" : ""}`}>
//                                {(item?.t_image || item?.t_image_path) && <img src={item?.t_image || item?.t_image_path} alt={item?.t_name} className={`object-cover ${title==="Geos"?"w-[50px] h-[50px]":"w-full h-full"} `} />}
//                                {item?.year &&<div className="text-white text-3xl lg:text-5xl">{item?.year}'s</div>}
//                                </Link>
//                                 {isOptions === item.id &&<div onMouseLeave={()=>setLink(true)} onMouseEnter={()=>setLink(false)} className="absolute cursor-default h-16 w-full z-[20] bottom-0 bg-gradient-to-t from-neutral-950 hidden  bg-opacity-35  lg:flex justify-between items-end pb-2 px-2">
//                                     <div>
//                                         <Link  to={`/collection/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}?id=${item?.id}&play=true`}>
//                                         <LazyLoadImage
//                                             alt=''
//                                             className="cursor-pointer"
//                                             src="/play-circle.png"
//                                             />
//                                         </Link>
//                                         {/* <img src="/play-circle.png" alt="" className="cursor-pointer" /> */}
//                                     </div>
//                                     <div onClick={()=>handleClickOpen(item?.v_code ? item?.v_code : item?.v_year ? item?.v_year : item?.iso,item?.id)}>
//                                     <LazyLoadImage
//                                         alt=''
//                                         className="cursor-pointer"
//                                         src="/share-icon.png"
//                                         />
//                                         {/* <img src="/share-icon.png" alt="" className="cursor-pointer" /> */}
//                                     </div>
//                                 </div>}

//                                 <div className="absolute cursor-default h-16 w-full z-[20] bottom-0 bg-gradient-to-t from-neutral-950 lg:hidden  bg-opacity-35  flex justify-between items-end pb-2 px-2">
//                                     <div>
//                                         <Link  to={`/collection/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}?id=${item?.id}&play=true`}>
//                                         <LazyLoadImage
//                                             alt=''
//                                             className="cursor-pointer"
//                                             src="/play-circle.png"
//                                             />
//                                         </Link>
//                                         {/* <img src="/play-circle.png" alt="" className="cursor-pointer" /> */}
//                                     </div>
//                                     <div onClick={()=>handleClickOpen(item?.v_code ? item?.v_code : item?.v_year ? item?.v_year : item?.iso,item?.id)}>
//                                     <LazyLoadImage
//                                         alt=''
//                                         className="cursor-pointer"
//                                         src="/share-icon.png"
//                                         />
//                                         {/* <img src="/share-icon.png" alt="" className="cursor-pointer" /> */}
//                                     </div>
//                                 </div>

//                             </div>
//                             <div className=" w-full text-center mt-2">
//                                 <Link to={`/collection/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}`} className="text-[#312522] line-clamp-1 text-base font-semibold dark:text-white">{item?.t_name || item?.t_genres || item?.v_name}</Link>
//                                 <Link  to={`/collection/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}`} className="text-[#312522] text-base line-clamp-1 font-semibold dark:text-white">{item?.v_region || item?.v_range}</Link>
//                             </div>
//                             {title==="Albums" && <Link to={`/collection/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}`} className="line-clamp-1 text-center text-sm text-[#B7B7B7] truncate dark:text-white">{item?.details}</Link>}
//                         </div>
//                     )
//                 })}
//             </div>
//            }

//         </div>
//     )
// }

// export default CommonListing

import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import ShareModal from "./ShareModal";
import { handleCloseModal, handleOpenModal } from "../../../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleShareModal } from "../../../store/shareModalGlobalSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleOutlineTwoToneIcon from "@mui/icons-material/PlayCircleOutlineTwoTone";
import { useApi } from "../../../api/api";

function CommonListing({
  listing,
  title,
  className,
  imgHeight,
  prop,
  type,
  handleDeletePlaylist,
  flag,
  hidetitle,
  fromprofile = "",
}) {
  const loadingstates = useSelector((state) => state.loader.isLoading);
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [isOptions, setIsOptions] = useState(null);
  const [link, setLink] = useState(true);
  const [ids, setId] = useState("");
  console.log("typeee", type);
  // Assuming isLoading is passed as a prop indicating if the data is still loading
  // const { isLoading } = props;

  const handleClickOpen = (id, idss) => {
    console.log("idss", idss);
    localStorage.setItem("itemId", idss);
    console.log();
    // dispatch(handleShareModal(`https://curioushit-frontend.nevinainfotech.in${prop === "Artists"? '/collection/albums' : fromprofile?fromprofile:location.pathname}/${id}`));
    dispatch(
      handleShareModal(
        `${
          prop === "Artists"
            ? "/collection/albums"
            : title
            ? title.toLowerCase()   
            : location.pathname
        }/${id}`
      )
    );

    const newUrl = `${location.pathname}?${Date.now()}`;
    window.history.pushState({}, "", newUrl);
  };
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const handlePopState = (event) => {
      //    callback(event);
      //   setNotificationPopUpMobile(false)
      dispatch(handleShareModal());
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="relative px-3 lg:px-0">
      <ShareModal id={ids} location={location} fromprofile={fromprofile} />
      {title !== "playlists" && title !== "artists" && (
        <h2
          className={`text-[#312522] ${
            location.pathname === "/global-search" ? "hidden" : ""
          } my-5 font-semibold text-xl dark:text-white  ${
            hidetitle && "!hidden"
          }`}
        >
          {id ? "" : title}
        </h2>
      )}
      {loadingstates ? (
        // Show a loading indicator or skeleton loader here
        <div className="">
          <div
            className={`animate-pulse  grid grid-cols-2 lg:grid-cols-6 ${
              flag === "profilePage" && "lg:grid-cols-3"
            } gap-8`}
          >
            {[...Array(30)]?.map((_, index) => (
              <div>
                <div
                  key={index}
                  className=" bg-gray-300 rounded w-44 h-44"
                ></div>
                <div className="flex w-full justify-center items-center mt-1">
                  <p className="bg-gray-300 w-32 rounded-2xl h-4 mt-3 mx-auto"></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : listing?.length < 1 ? (
        <div>
          <div className="py-16 bg-white dark:bg-[#151515] dark:text-white drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
            <p className="lg:text-xl text-lg font-semibold">
              {type === "all"
                ? "No playlist Found"
                : type === "liked"
                ? "No Liked playlist Found"
                : "No Shared playlist Found"}
            </p>
          </div>
        </div>
      ) : (
        <div
          className={`grid grid-cols-2 md:grid-cols-3  ${
            flag === "profilePage" ? "lg:grid-cols-3" : "lg:grid-cols-6"
          } gap-4 mt-2 !my-7 ${className}`}
        >
          {listing?.map((item, index) => {
            return (
              // <Link  to={link ? `/${title.toLowerCase()}/${item.v_code ? item.v_code : item.v_year ? item.v_year : item.iso}` : '#'}
              <div
                key={item?.id}
                className={`rounded-md ${
                  flag === "collectionPage"
                    ? ""
                    : flag === "profilepagelisting"
                    ? index > 5 && "!hidden"
                    : index > 2 && "!hidden"
                } cursor-pointer relative`}
              >
                <div
                  className={` w-full  h-40  overflow-hidden rounded-xl relative flex justify-center items-center ${
                    title === "Geos" ? "bg-white" : ""
                  } ${imgHeight}`}
                  style={{ backgroundColor: item?.v_color_code }}
                  onMouseEnter={() => setIsOptions(item.id)}
                  onMouseLeave={() => setIsOptions(null)}
                >
                  {/* <div className="absolute top-0 w-full h-full bg-black opacity-35"></div> */}

                  <div className="absolute top-0 border-t-transparent left-[4%] border-[2px] flex justify-center items-center text-center  h-7  rounded-b-3xl border-white p-2">
                    <div className="text-[10px] text-white">
                      <p>{item?.v_total_songs}</p>
                    </div>
                  </div>

                  <Link
                    to={`/collection/${title.toLowerCase()}/${
                      item.v_code
                        ? item.v_code
                        : item.v_year
                        ? item.v_year
                        : item.iso
                    }?id=${item?.id}&globalid=${
                      item?.is_global ? true : false
                    }`}
                    className={`h-40  w-full ${
                      title === "Geos" || title === "Decades"
                        ? "flex flex-col justify-center items-center"
                        : ""
                    }`}
                  >
                    {(item?.t_image || item?.t_image_path) && (
                      <img
                        src={item?.t_image || item?.t_image_path}
                        alt={item?.t_name}
                        className={`object-cover ${
                          title === "Geos"
                            ? "w-[50px] h-[50px]"
                            : "w-full h-full"
                        } `}
                      />
                    )}
                    {item?.year && (
                      <div className="text-white text-3xl lg:text-5xl">
                        {item?.year}'s
                      </div>
                    )}
                  </Link>
                  {isOptions === item.id && (
                    <div
                      onMouseLeave={() => setLink(true)}
                      onMouseEnter={() => setLink(false)}
                      className="absolute cursor-default h-16 w-full z-[20] bottom-0 bg-gradient-to-t from-neutral-950 hidden  bg-opacity-35  lg:flex justify-between items-end pb-2 px-2"
                    >
                      <div>
                        <Link
                          to={`/collection/${title.toLowerCase()}/${
                            item.v_code
                              ? item.v_code
                              : item.v_year
                              ? item.v_year
                              : item.iso
                          }?id=${item?.id}&play=true&globalid=${
                            item?.is_global ? true : false
                          }`}
                        >
                          {/* <LazyLoadImage
                                            alt='' 
                                            className="cursor-pointer"
                                            src="/play-circle.png"
                                            /> */}
                          {item?.v_total_songs > 0 && (
                            <PlayCircleIcon className="!bg-white !text-[#F1B942] !rounded-full !border-none" />
                          )}
                        </Link>
                        {/* <img src="/play-circle.png" alt="" className="cursor-pointer" /> */}
                      </div>
                      <div>
                        {/* <LazyLoadImage
                                        alt='' 
                                        className="cursor-pointer"
                                        src="/share-icon.png"
                                        /> */}
                        {item?.v_total_songs > 0 ? (
                          <ShareRoundedIcon
                            onClick={() =>
                              handleClickOpen(
                                item?.v_code
                                  ? item?.v_code
                                  : item?.v_year
                                  ? item?.v_year
                                  : item?.iso,
                                item?.id
                              )
                            }
                            className="!text-white !rounded-full !p-1 !bg-[#F1B942] !border-none"
                          />
                        ) : (
                          <DeleteIcon
                            onClick={() => handleDeletePlaylist(item?.id)}
                            className="!text-white !rounded-full !bg-[#F1B942] !border-none !p-1 cursor-pointer"
                          />
                        )}
                        {/* <img src="/share-icon.png" alt="" className="cursor-pointer" /> */}
                      </div>
                    </div>
                  )}

                  <div className="absolute cursor-default h-16 w-full lg:z-[20] bottom-0 bg-gradient-to-t from-neutral-950 lg:hidden  bg-opacity-35  flex justify-between items-end pb-2 px-2">
                    <div>
                      <Link
                        to={`/collection/${title.toLowerCase()}/${
                          item.v_code
                            ? item.v_code
                            : item.v_year
                            ? item.v_year
                            : item.iso
                        }?id=${item?.id}&play=true&globalid=${
                          item?.is_global ? true : false
                        }`}
                      >
                        {/* <LazyLoadImage
                                            alt='' 
                                            className="cursor-pointer"
                                            src="/play-circle.png"
                                            /> */}
                        {item?.v_total_songs > 0 && (
                          <PlayCircleIcon className="!bg-white !text-[#F1B942] !rounded-full !border-none" />
                        )}
                      </Link>
                      {/* <img src="/play-circle.png" alt="" className="cursor-pointer" /> */}
                    </div>
                    <div>
                      {/* <LazyLoadImage
                                        alt='' 
                                        className="cursor-pointer"
                                        src="/share-icon.png"
                                        /> */}
                      {item?.v_total_songs > 0 ? (
                        <ShareRoundedIcon
                          onClick={() =>
                            handleClickOpen(
                              item?.v_code
                                ? item?.v_code
                                : item?.v_year
                                ? item?.v_year
                                : item?.iso,
                              item?.id
                            )
                          }
                          className="!text-white !rounded-full !p-1 !bg-[#F1B942] !border-none"
                        />
                      ) : (
                        <DeleteIcon
                          onClick={() => handleDeletePlaylist()}
                          className="!text-white !rounded-full !bg-[#F1B942] !border-none !p-1 cursor-pointer"
                        />
                      )}

                      {/* <img src="/share-icon.png" alt="" className="cursor-pointer" /> */}
                    </div>
                  </div>
                </div>
                <div className=" w-full text-center mt-2">
                  <Link
                    to={`/collection/${title.toLowerCase()}/${
                      item.v_code
                        ? item.v_code
                        : item.v_year
                        ? item.v_year
                        : item.iso
                    }`}
                    className="text-[#312522] capitalize line-clamp-1 text-base font-semibold dark:text-white"
                  >
                    {item?.t_name || item?.t_genres || item?.v_name}
                  </Link>
                  <Link
                    to={`/collection/${title.toLowerCase()}/${
                      item.v_code
                        ? item.v_code
                        : item.v_year
                        ? item.v_year
                        : item.iso
                    }`}
                    className="text-[#312522] capitalize text-base line-clamp-1 font-semibold dark:text-white"
                  >
                    {item?.v_region || item?.v_range}
                  </Link>
                </div>
                {title === "Albums" && (
                  <Link
                    to={`/collection/${title.toLowerCase()}/${
                      item.v_code
                        ? item.v_code
                        : item.v_year
                        ? item.v_year
                        : item.iso
                    }`}
                    className="line-clamp-1 capitalize text-center text-sm text-[#B7B7B7] truncate dark:text-white"
                  >
                    {item?.details}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CommonListing;
