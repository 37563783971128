import React, { useState } from 'react'
import GenresSwiper from '../GenresSwiper'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import InstrumentUpdate from './InstrumentUpdateComponent';


const InstrumentComponent = ({ genresList, flag, showEdit}) => {
  const [openInstruModal, setOpenInstruModal] = useState(false);

  return (
    <div className="dark:text-[#DDD1CE] ">
      <InstrumentUpdate
        openInstruModal={openInstruModal}
        setOpenInstruModal={setOpenInstruModal}
      />
      {showEdit && (
        <div className={`absolute top-2 right-0 min-w-10  block`}>
          <button className=" w-5 h-5">
            <BorderColorOutlinedIcon
              onClick={() => setOpenInstruModal(true)}
              className="!text-[#312522] dark:!text-[#A8A8A8] hover:!text-[#F1B942]"
            />
          </button>
        </div>
      )}
      <GenresSwiper genresList={genresList} flag2="instrument" flag={flag} />
    </div>
  );
};

export default InstrumentComponent