// import React, { useState } from 'react'
// import SearchIcon from '@mui/icons-material/Search';

// const GlobalSearchBar = () => {
//     const [expanded, setExpanded] = useState(false);
//     const [searchTerm, setSearchTerm] = useState("");
//     const handleSearchInputChange = (event) => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearch = () => {
//         // Implement your search functionality here
//         console.log("Searching for:", searchTerm);
//         // You can perform search operations based on the searchTerm
//         // For example, you can make an API call to fetch search results
//     };

//     const handleSearchIconClick = () => {
//         setExpanded(true);
//     };

//   return (
//     <div className="relative w-full mx-5 flex justify-end text-right">
//     <input
//        type="text"
//        placeholder="Search Tracks, Playlists, Artists..."
//        className={`bg-white ${expanded ? 'w-full' : 'w-[40px]'} rounded-[50px] h-[40px] px-12 lg:px-5 text-[#A1A1A1] text-[12px]  transition-width duration-300 `}
//        value={searchTerm}
//        onChange={handleSearchInputChange}
//     />
//     <span onClick={()=>handleSearchIconClick()} className={`absolute top-1/2 transform right-[10px]    duration-300 text-[#A1A1A1] fill-[#A1A1A1] -translate-y-1/2 cursor-pointer`}><SearchIcon/></span>
// </div>
//   )
// }

// export default GlobalSearchBar

import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useLocation, useNavigate } from "react-router";
import {
  handleGlobalSearchValue,
  handleGlobalSuggValue,
} from "../../../../store/globalSearchSlice";
import { handleLoginSidebar } from "../../../../store/mobileSideBarSlice";
import { useApi } from "../../../../api/api";
import { Link } from "react-router-dom";
import {
  handleCurrentSongIndex,
  handleIsRecommended,
  handlePlayerStatus,
} from "../../../../store/playerSlice";
import { handleCountSongs } from "../../../../store/trackLikeSlice";
import CloseIcon from "@mui/icons-material/Close";
import useEnterKeyBlur from "../../../../hooks/useEnterKeyBlur";

const GlobalSearchBar = ({
  handleLogin,
  setExpanded,
  expanded,
  searchValue,
}) => {
  const searchContainerRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const location = useLocation();
  const authenticationToken = JSON.parse(
    localStorage.getItem("AuthToken") ?? sessionStorage.getItem("AuthToken")
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(searchValue ? searchValue : "");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const { handleGetRequest, handlePostRequest } = useApi();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  console.log("adcaocation", location);
  const pathname = location.pathname;

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setShowSuggestions(false); // Hide suggestions if click is outside
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    pathname !== "/global-search" && localStorage.setItem("searchValue", "");
  }, [pathname]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    if (searchTerm === "") {
      return;
    }
    // Implement your search functionality here
    console.log("Searching for:", searchTerm);
    localStorage.setItem("searchValue", searchTerm);
    dispatch(handleGlobalSearchValue(searchTerm));
    navigate("/global-search");

    // You can perform search operations based on the searchTerm
    // For example, you can make an API call to fetch search results
  };
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsResults, setSuggestionsResults] = useState([]);

  // useEffect(()=>{
  //     const suggestionRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, false,false);
  //     console.log("suggestionRes",suggestionRes);

  //     setSuggestions([]);
  // },[searchTerm])
  const isFirstRender = useRef(true);

  useEffect(() => {
    //  if (isFirstRender.current) {
    //         isFirstRender.current = false;
    //         return;
    //     }

    (async () => {
      try {
        if (searchTerm) {
          const suggestionRes = await handleGetRequest(
            `track/suggestions?keyword=${searchTerm}`,
            false,
            false
          );
          setSuggestions(suggestionRes?.data?.suggestions);
          setSuggestionsResults(suggestionRes?.data?.search_results);
        }
      } catch (error) {
        console.error("Error fetching suggestions", error);
      }
    })();
  }, [searchTerm]);

  console.log("suggestions", suggestions);

  useEffect(() => {
    if (!authenticationToken) {
      setSearchTerm("");
    }
  }, [authenticationToken]);

  // const handleSearchIconClick = () => {
  //     if(searchTerm || expanded ){
  //         return
  //     }else{
  //         setExpanded(pre=>!pre);
  //         setIsFocused(true);
  //         inputRef.current.focus();
  //     }
  // };

  const handleSearchIconClick = () => {
    if (searchTerm) {
      return;
    }
    setExpanded((pre) => !pre);
    setIsFocused((pre) => !pre);
    inputRef.current.focus();
  };

  // useEffect(()=>{
  //     if(isFocused){
  //         return
  //     }
  //     (!isFocused && !searchTerm) && setExpanded(false);
  // },[isFocused,searchTerm])

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
      setSearchTerm(localStorage.getItem("searchValue", searchTerm));
      setSearchTerm("");
      event.target.blur();
    }
  };

  useEffect(() => {
    dispatch(handleLoginSidebar(searchTerm ? true : false));
    if (searchTerm) {
      setShowSuggestions(true);
    }
  }, [searchTerm]);

  const handleGlobalSearch = (searchterm) => {
    setSearchTerm(searchterm);
    localStorage.setItem("searchValue", searchterm);
    dispatch(handleGlobalSuggValue(searchterm));
    if (location.pathname !== "/global-search") {
      navigate("/global-search");
    }
    setShowSuggestions(false);
    // setSearchTerm("")
  };

  const countTracks = useSelector((state) => state.likeSongs.countSongs);

    const playSong = (songData,currentSongIndex,id) => {
        dispatch(handleIsRecommended(false))
        localStorage.setItem('playingCurrentSong',  JSON.stringify(songData))
        dispatch(handlePlayerStatus(songData?.e_platform));
        dispatch(handleCurrentSongIndex(currentSongIndex));
        dispatch(handleCountSongs(countTracks.map(item =>
            item.id === id ? { ...item, play_count: item.play_count + 1 } : item
        )))

        setShowSuggestions(false)
      }



  const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  useEffect(() => {
    setShowSuggestions(false);
  }, []);

  console.log("suggestionsResults", suggestionsResults);
  useEnterKeyBlur(searchContainerRef);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter" && selectedIndex !== -1) {
      //   alert(`You selected: ${suggestions[selectedIndex]}`);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, suggestions]);

  return (
    <div
      ref={searchContainerRef}
      className="relative w-full ml-5 mr-2 flex justify-end text-right"
    >
      <input
        ref={inputRef}
        type="text"
        placeholder={`${
          expanded ? "Search Tracks, Playlists, Artists..." : ""
        }`}
        className={`bg-white ${
          expanded ? "w-full" : "w-[40px]"
        } rounded-[50px] h-[40px] pl-4 lg:px-5 text-[#A1A1A1] text-[12px]  transition-width duration-300  dark:bg-[#151515] dark:!text-white`}
        defaultValue={searchValue}
        value={searchTerm}
        disabled={!expanded}
        onChange={handleSearchInputChange}
        onBlur={() => setIsFocused(false)}
        onKeyPress={handleKeyPress}
      />

      {showSuggestions && searchTerm ? (
        <span
          onClick={() => setShowSuggestions(false)}
          className={`absolute top-1/2 transform right-[0px] dark:bg-[#151515] bg-white p-2 duration-300 dark:!text-[#A1A1A1] text-[#A1A1A1] fill-[#A1A1A1] -translate-y-1/2 cursor-pointer  rounded-3xl`}
        >
          <CloseIcon />
        </span>
      ) : (
        <span
          onClick={() => {
            handleSearchIconClick();
            handleLogin();
          }}
          className={`absolute top-1/2 transform right-[0px] dark:bg-[#151515] duration-300 bg-white p-2 rounded-3xl dark:!text-[#A1A1A1] text-[#A1A1A1] fill-[#A1A1A1] -translate-y-1/2 cursor-pointer `}
        >
          <SearchIcon />
        </span>
      )}

      {showSuggestions && searchTerm && (
        <div className=" lg:max-h-80 lg:min-h-40 left-0 lg:mt-0 mt-2 h-screen z-50 w-full bg-white dark:lg:bg-[#343434] dark:bg-[#0D0D0D] drop-shadow-lg dark:text-white rounded-2xl py-5 lg:p-0 fixed lg:absolute top-10 overflow-y-auto">
          {suggestions?.map((sugg, index) => (
            <div
              onClick={() => {
                handleGlobalSearch(sugg);
                setSearchTerm("");
              }}
              key={index}
              className={`text-left  cursor-pointer `}
            >
              {/* Render suggestion content here */}
              <div
                className={`flex justify-start items-center gap-x-2 px-5 hover:bg-[#FFFAEF] rounded-full ${
                  selectedIndex === index ? "bg-gray-200" : ""
                }`}
              >
                <SearchIcon className="!text-base dark:lg:text-[#A1A1A1]" />
                <p className="text-sm my-1 dark:lg:text-[#A1A1A1]">{sugg}</p>
              </div>
            </div>
          ))}

          <hr className="text-[#797777] bg-slate-500 my-5 lg:hidden" />

          {suggestionsResults?.map((suggResult, index) => (
            <div className="mt-3 ">
              {suggResult?.type === "song" ? (
                <div
                  className="text-left my-2 cursor-pointer px-5"
                  onClick={() => {
                    playSong(suggResult, index, suggResult?.id);
                    setSearchTerm("");
                  }}
                >
                  <div className="flex justify-start items-center gap-x-5 my-2 hover:bg-[#FFFAEF] dark:hover:text-black rounded-full">
                    <div className=" min-w-10 max-w-10 h-10 rounded-full">
                      <img
                        src={suggResult?.t_image || suggResult?.v_profile_pic}
                        alt=""
                        className="rounded-full w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <p className="line-clamp-1">
                        {capitalizeFirstLetter(
                          suggResult?.t_title ||
                            suggResult?.v_name ||
                            suggResult?.t_name
                        )}
                      </p>
                      <p>{capitalizeFirstLetter(suggResult?.type)}</p>
                    </div>
                  </div>
                </div>
              ) : suggResult?.type === "user" ? (
                <Link
                  onClick={() => {
                    setShowSuggestions(false);
                    setSearchTerm("");
                  }}
                  to={`/other-user/${suggResult?.id}`}
                >
                  <div className="flex justify-start items-center gap-x-5  px-5 hover:bg-[#FFFAEF] rounded-full">
                    <div className="w-10 h-10 rounded-full">
                      <img
                        src={suggResult?.t_image || suggResult?.v_profile_pic}
                        alt=""
                        className="rounded-full w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <p className="line-clamp-1">
                        {capitalizeFirstLetter(
                          suggResult?.t_title ||
                            suggResult?.v_name ||
                            suggResult?.t_name
                        )}
                      </p>
                      <p className="text-left">
                        {capitalizeFirstLetter(suggResult?.type)}
                      </p>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    setShowSuggestions(false);
                    setSearchTerm("");
                  }}
                  to={`${`/collection/${
                    suggResult?.type === "playlist"
                      ? "playlists"
                      : suggResult?.type === "album"
                      ? "albums"
                      : "artists"
                  }/${suggResult?.v_code}?id=${suggResult?.id}&globalid=true`}`}
                  key={index}
                  className="text-left  "
                >
                  {/* Render suggestion content here */}
                  <div className="flex justify-start items-center gap-x-5  px-5 hover:bg-[#FFFAEF] dark:hover:text-black rounded-full">
                    <div className="w-10 h-10 rounded-full">
                      <img
                        src={suggResult?.t_image || suggResult?.v_profile_pic}
                        alt=""
                        className="rounded-full w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <p className="line-clamp-1">
                        {capitalizeFirstLetter(
                          suggResult?.t_title ||
                            suggResult?.v_name ||
                            suggResult?.t_name
                        )}
                      </p>
                      <p className="text-left">
                        {capitalizeFirstLetter(suggResult?.type)}
                      </p>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchBar;
