import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!;
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { Dialog } from "primereact/dialog";
import CreateCalenderModal from "./CreateCalenderModal";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import H220 from "../common/Typograpgy/H220";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
// import { Toast } from 'primereact/toast';
import { useApi } from "../../../api/api";
import { Link, useLocation } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import Button1 from "../common/GlobalButtonLayout/Button1";
import SettingsIcon from "@mui/icons-material/Settings";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Slider from "@mui/material/Slider";

import moment from "moment";
import globalToast from "../../../hooks/globalToast";

const TimelineCalender = ({
  calendarEvents,
  setCalendarEvents,
  dateChange,
  activityType,
  setShowEventModal,
  showEventModal,
  setIsDeleteModal,
  isDeleteModal,
}) => {
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isShuffleEnabled, setIsShuffleEnabled] = useState(false);
  const [isPlaylistPublicEnabled, setIsPlaylistPublicEnabled] = useState(false);
  const [isRemindEnabled, setIsRemindEnabled] = useState(false);
  const userData = useSelector((state) => state.auth.profileData);
  const [isSettingModal, setIsSettingModal] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const bluetooth = useSelector((state) => state.bluetooth.bluetoothDevice);
  const [isPremiumModal, setisPremiumModal] = useState(false);
  const [isSchdule, setIsSchdule] = useState(false);

  const isDark = useSelector((state) => state.dark.isDarkValue);
  const { handleGetRequest, handlePostRequest } = useApi();

  const handleShowEvent = (eventInfo) => {
    if (!userData?.user?.is_premium_subscriber) {
      setisPremiumModal(true);
      return;
    }
    setShowEventModal(eventInfo.event.extendedProps.publicId);
    const newUrl = `${location.pathname}?${Date.now()}`;
    window.history.pushState({}, "", newUrl);
  };
  const renderEventContent = (eventInfo) => (
    <div
      onClick={() => handleShowEvent(eventInfo)}
      className="text-pretty cursor-pointer"
      style={{ width: "-webkit-fill-available" }}
    >
      {console.log("eventInfo", eventInfo.event)}
      <p className="font-bold text-ellipsis hover:!bg-[#F1B942] hover:!rounded-2xl overflow-hidden line-clamp-1 bg-[#F1B942] text-white rounded-2xl px-5 text-[10px] lg:text-base ">
        {`${eventInfo.event.title
          .charAt(0)
          .toUpperCase()}${eventInfo.event.title.slice(1)}`}
      </p>
      {/* <p>{eventInfo.event.extendedProps.description}</p> */}
    </div>
  );

  // console.log("calendarEventsdd",calendarEvents?.data?.events)

  //   const formattedEvents = calendarEvents?.data?.map(event => ({
  //     publicId:event?.id,
  //     title: event.t_title,
  //     start: `${event.d_schedule_start_date}T${event.tm_schedule_start_time}`,
  //     end: `${event.d_schedule_end_date}T${event.tm_schedule_end_time}`,
  //     description: event.t_description
  // }));

  // Assuming calendarEvents is your original array of events

  const formattedEvents = calendarEvents?.data?.events?.map((event) => {
    // Check if the event has a 'days' array and it's not empty
    if (Array.isArray(event.days) && event.days.length > 0) {
      // Map the days from string values to numeric values
      const daysOfWeek = event.days
        .map((day) => {
          switch (day) {
            case "SU":
              return 0;
            case "MO":
              return 1;
            case "TU":
              return 2;
            case "WE":
              return 3;
            case "TH":
              return 4;
            case "FR":
              return 5;
            case "SA":
              return 6;
            default:
              return null; // Fallback in case of unknown value
          }
        })
        .filter((day) => day !== null); // Remove any null values in case of unknown days

      // Return the event object with the daysOfWeek property for recurring events
      return {
        publicId: event.id,
        title: event.t_title,
        start: `${event.d_schedule_start_date}T${event.tm_schedule_start_time}`,
        end: `${event.d_schedule_end_date}T${event.tm_schedule_end_time}`,
        daysOfWeek: daysOfWeek,
        description: event.t_description,
        allDay: event.e_all_day === "1", // Convert string to boolean
      };
    } else {
      // For non-recurring events, format them as before
      return {
        publicId: event.id,
        title: event.t_title,
        start: `${event.d_schedule_start_date}T${event.tm_schedule_start_time}`,
        end: `${event.d_schedule_end_date}T${event.tm_schedule_end_time}`,
        description: event.t_description,
        allDay: event.e_all_day === "1", // Convert string to boolean
      };
    }
  });

  // Then, pass formattedEvents to your FullCalendar component as before

  console.log("calendarEvents?.data", formattedEvents);

  // useEffect(()=>{
  //   const index = findEventIndexById(calendarEvents, showEventModal) {
  //     return events.findIndex(event => event.id === id);
  // }},[showEventModal])

  const [eventIndex, setEventIndex] = useState();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setEventIndex(
        calendarEvents?.data?.events?.findIndex(
          (event) => event?.id === showEventModal
        )
      );
    }
  }, [showEventModal, calendarEvents]);
  // const toast = useRef(null);

  const accept = async () => {
    setIsDeleteModal(false);

    const payload = {};
    const deleteEvent = await handlePostRequest(
      `google-calendar/deleteEvent/${showEventModal}`,
      payload,
      true,
      true
    );
    console.log("deleteEvent", deleteEvent);
    if (deleteEvent?.flag) {
      const getEvents = await handleGetRequest(
        `google-calendar/getEvents`,
        false,
        false
      );
      console.log("succesyncalender", getEvents);
      setCalendarEvents(getEvents);
      setShowEventModal();
    } else {
      // toast.error(deleteEvent?.msg);
      globalToast(deleteEvent?.msg, true);
    }
  };

  const reject = () => {};

  const confirm2 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Do you want to delete this Event?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  function minutesUntilEvent(eventDate, eventTime) {
    // Combine date and time into a single Date object
    const eventDateTime = new Date(`${eventDate}T${eventTime}`);
    const currentDateTime = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = eventDateTime - currentDateTime;

    // Convert milliseconds to minutes
    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / 1000 / 60
    );

    return differenceInMinutes;
  }

  const minutesLeft = minutesUntilEvent(
    calendarEvents?.data?.events[eventIndex]?.d_schedule_start_date,
    calendarEvents?.data?.events[eventIndex]?.tm_schedule_start_time
  );

  const date = new Date(
    calendarEvents?.data?.events[eventIndex]?.d_schedule_start_date
  );

  const options = { day: "numeric", month: "long" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  function convertTimeTo12HourFormat(time) {
    // Check if time is a valid string and contains at least one colon
    if (typeof time !== "string" || !/:/.test(time)) {
      console.error('Invalid time format. Expected "HH:mm:ss"');
      return "Invalid Time";
    }

    // Split the time string into hours, minutes, and seconds
    let [hours, minutes, seconds] = time.split(":");

    // Ensure all parts are present
    if (!hours || !minutes) {
      console.error('Incomplete time format. Expected "HH:mm:ss"');
      return "Invalid Time";
    }

    // Convert hours from string to number
    hours = parseInt(hours, 10);

    // Determine the period (AM/PM)
    const period = hours >= 12 ? "PM" : "AM";

    // Adjust hours for 12-hour format
    hours = hours % 12 || 12;

    // Format hours and minutes to be two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.padStart(2, "0");

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  const stTime = convertTimeTo12HourFormat(
    calendarEvents?.data?.events[eventIndex]?.tm_schedule_start_time
  );
  const endTime = convertTimeTo12HourFormat(
    calendarEvents?.data?.events[eventIndex]?.tm_schedule_end_time
  );
  const calendarRef = useRef(null);
  const changeDate = (date) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(date);
    }
  };

  useEffect(() => {
    if (dateChange) {
      const date = new Date(dateChange);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      changeDate(formattedDate);
    }
  }, [dateChange]);

  const [dateFromClick, setDateFromClick] = useState("");
  const [timeFromClick, setTimeFromClick] = useState("");

  const location = useLocation();
  const ProfileData = useSelector((state) => state.auth.profileData);

  const parser = new DOMParser();
  const doc = parser.parseFromString(
    ProfileData?.user?.current_subscription.t_features,
    "text/html"
  );

  const featureItems = doc.querySelectorAll("li");

  const featureNames = Array.from(featureItems).map((item) =>
    item?.textContent.trim()
  );

  console.log(featureNames.includes("Schedule"), "fNamess");
  const checkIsPremium = () => {
    if (
      ProfileData?.user?.current_subscription?.i_schedule == 0 ||
      !userData?.user?.is_premium_subscriber
    ) {
      setisPremiumModal(true);
      return;
    }
    setIsCreateModal(true);
    // setIsSchdule(true)
    setDateFromClick(Date.now());
    const newUrl = `${location.pathname}?${Date.now()}`;
    window.history.pushState({}, "", newUrl);
  };

  console.log(`Code1: for Mobile Back button : `, checkIsPremium);

  const handleDateClick = (clickInfo) => {
    console.log("Dateclicked:", clickInfo);
    if (ProfileData?.user?.current_subscription?.i_schedule == 0) {
      setisPremiumModal(true);
      return;
    }
    setDateFromClick(clickInfo.dateStr);
    setTimeFromClick(clickInfo.dateStr);
    setIsCreateModal(true);
    const newUrl = `${location.pathname}?${Date.now()}`;
    window.history.pushState({}, "", newUrl);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      //    callback(event);
      setIsCreateModal(false);
      setShowEventModal();
      setIsSettingModal(false);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#312522" : "#F1B942",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: isDark ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const handleSetting = () => {
    console.log(volume, "volume");
  };
  const handleChange = (event, para) => {
    para === "remind" && setIsRemindEnabled(event.target.checked);
    para === "public" && setIsPlaylistPublicEnabled(event.target.checked);
    para === "shuffle" && setIsShuffleEnabled(event.target.checked);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    setIsMuted(newValue === 0);
  };

  const [devices, setDevices] = useState([]);

  console.log("devices", devices);

  useEffect(() => {
    const requestBluetoothDevice = async () => {
      try {
        const device = await navigator.bluetooth.requestDevice({
          acceptAllDevices: true,
          optionalServices: ["battery_service"],
        });
        setDevices([device]);
      } catch (error) {
        console.error("Error accessing Bluetooth devices:", error);
      }
    };

    requestBluetoothDevice();
  }, []);

  // const handleScanDevices = async () => {
  //   try {
  //     const device = await navigator.bluetooth.requestDevice({
  //       filters: [{ services: ['battery_service'] }] // Change this based on your needs
  //     });

  //     setDevices((prevDevices) => [
  //       ...prevDevices,
  //       { name: device.name, id: device.id },
  //     ]);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  console.log("bluethoothdevices", formattedEvents);

  // past date event  removal
  //  const eventDidMount = (info) => {
  //   const today = new Date();
  //   const eventStart = moment(info.event.start);
  //   const eventDate = new Date(info.event.start);

  //   if (eventDate < today) {
  //     const moreLink = info.el.querySelector('.fc-daygrid-day-bottom');
  //       if (moreLink) {
  //         moreLink.style.display = 'none'; // Hide the "+more" button for past events
  //       }
  //   }
  //     const now = moment();
  //     if (eventStart.isBefore(now, 'day')) {
  //       info.el.style.display = 'none'; // Hide the event element if the event is in the past
  //     }
  //   };

  const eventDidMount = (info) => {
    const today = moment().startOf("day"); // Use moment to get today's date at 00:00
    const eventStart = moment(info.event.start); // Event start time

    // Check if the event is in the past
    if (eventStart.isBefore(today, "day")) {
      const moreLink = info.el
        .closest(".fc-daygrid-day")
        ?.querySelector(".fc-daygrid-day-bottom");
      if (moreLink) {
        moreLink.style.display = "none"; // Hide the "+more" button for past events
      }
      info.el.style.display = "none"; // Hide the event element if it's in the past
    }
  };

  const openBluetoothSettings = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Android: Redirect to Bluetooth settings
      window.location.href =
        "intent:#Intent;action=android.settings.BLUETOOTH_SETTINGS;end";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS: Attempt to redirect to Bluetooth settings
      window.location.href = "App-prefs:root=Bluetooth";
    } else if (/Windows NT/i.test(userAgent)) {
      // Windows: Redirect to Bluetooth settings
      window.location.href = "ms-settings:bluetooth";
    } else {
      alert("Bluetooth settings cannot be opened directly on this platform.");
    }
  };

  return (
    <div className={`${isDark ? "dark" : ""}`}>
      <Dialog
        visible={isCreateModal}
        className={`lg:p-10 p-5 lg:max-h-[500px] lg:h-[500px] w-[90vw]  lg:w-[50vw] h-[70vh] text-center !bg-white !opacity-1 ${
          isDark ? "dark" : ""
        } dark:!bg-[#151515] drop-shadow-xl rounded-xl -top-[10px] lg:-top-[50px]`}
        onHide={() => setIsCreateModal(false)}
      >
        <HighlightOffIcon
          onClick={() => setIsCreateModal(false)}
          className="cursor-pointer absolute top-3 right-3 dark:!text-white"
        />
        <div className="flex  justify-center h-full  ">
          <CreateCalenderModal
            activityType={activityType}
            calendarEvents={calendarEvents}
            eventIndex={eventIndex}
            setCalendarEvents={setCalendarEvents}
            setIsCreateModal={setIsCreateModal}
            datefromclick={dateFromClick}
            timeFromClick={timeFromClick}
            isSchdule={isSchdule}
          />
        </div>
      </Dialog>

      <Dialog
        visible={isPremiumModal}
        className={`p-7 lg:h-auto w-[90vw] lg:w-[45vw] h-auto text-center !bg-white !opacity-1 ${
          isDark ? "dark" : ""
        } dark:!bg-[#151515] drop-shadow-xl rounded-xl `}
        onHide={() => setIsCreateModal(false)}
      >
        <HighlightOffIcon
          onClick={() => setisPremiumModal(false)}
          className="cursor-pointer absolute top-3 right-3 dark:!text-white"
        />
        <div className="flex flex-col justify-center h-full   py-10">
          <h2 className=" text-base lg:text-2xl font-semibold text-center dark:text-white">
            Upgrade to premium services
          </h2>
          <div className="flex justify-center items-center mt-10 ">
            <Link to="/premium">
              <Button1 className="dark:hover:!text-white">Upgrade</Button1>
            </Link>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={isSettingModal}
        className={`p-2 lg:p-7 lg:h-[70%] w-[100vw] lg:w-[45vw] h-auto text-center !bg-white !opacity-1 ${
          isDark ? "dark" : ""
        } dark:!bg-[#151515] drop-shadow-xl rounded-xl `}
        onHide={() => setIsCreateModal(false)}
      >
        <div className="flex flex-col justify-center lg:justify-normal h-full lg:h-[200%]   py-2">
          <div className="flex fixed bg-white lg:px-10 px-2 top-3 left-0 right-0 justify-between items-center">
            {" "}
            <h2 className=" text-lg lg:text-2xl font-semibold text-center dark:text-white">
              Confirm schedule setting
            </h2>
            <HighlightOffIcon
              onClick={() => setIsSettingModal(false)}
              className="cursor-pointer   dark:!text-white"
            />
          </div>

          <div className="text-left my-10 px-2 lg:px-10">
            <div className="flex justify-start items-center gap-x-1">
              <div className="bg-[#F1B942] w-16 h-16 rounded-lg">
                <img src="" alt="" className="" />
              </div>
              <div className="text-left  pl-1">
                <H220 className="!text-left !my-0  !text-sm lg:!text-base !text-black  ">
                  {calendarEvents?.data?.events[eventIndex]
                    ? calendarEvents?.data?.events[eventIndex]?.t_title
                    : "No Event Title"}
                </H220>

                <p className="text-sm lg:text-base text-black  dark:!text-white ">
                  {" "}
                  Event name songs to wind down to
                </p>
                <p className="text-sm lg:text-base text-black  dark:!text-white ">
                  {calendarEvents?.data?.events[eventIndex]?.playlists?.length}{" "}
                  Playlists
                </p>
              </div>
            </div>

            <div className="my-5">
              <H220 className="!text-left !my-0  !text-sm lg:!text-base !text-black  ">
                Time
              </H220>
              <div className="flex justify-between items-center my-5">
                <div>
                  <p className="text-sm font-bold ">Start</p>
                  <p className="text-sm text-[#F1B942]  my-3">{stTime}</p>
                </div>
                <div>
                  <p className="text-sm font-bold">End</p>
                  <p className="text-sm text-[#F1B942] my-3 ">{endTime}</p>
                </div>
              </div>
            </div>

            <div className="my-5">
              <H220 className="!text-left !my-0  !text-sm lg:!text-base !text-black ">
                Repeat
              </H220>
              <p className="text-sm  my-3 ">
                {calendarEvents?.data?.events[eventIndex]?.repeat
                  ? ""
                  : "No repeat"}
              </p>
            </div>

            {/* <div className='my-5'>
          
            <div className='flex justify-between items-center my-5'>
              <div>
                <p className='text-sm font-bold '>Notification starts</p>
                <p className='text-sm  my-3'>Every weekday</p>
              </div>
              <div>
                <p className='text-sm font-bold'>Notification ends</p>
                <p className='text-sm  my-3 '>For similar tracks</p>
              </div>
            </div>
          </div> */}

            <div className="grid grid-cols-2 gap-x-5 gap-y-2 my-5">
              <p>Connect to device</p>
              {!devices[0]?.name && (
                <button onClick={openBluetoothSettings}>
                  Request bluetooth device
                </button>
              )}
              {/* {!devices[0]?.name && <button onClick={requestBluetoothDevice}>Request bluetooth device</button>} */}
              {devices[0]?.name && (
                <p>
                  {devices[0]?.name
                    ? devices[0]?.name
                    : "Not Found / Not connected to any device"}
                </p>
              )}

              {/* {devices[0]((items,index)=>{
                return(
                  <p id={index}> {items} </p>
                )
              })} */}
            </div>

            <div className="grid grid-cols-2 gap-x-5 gap-y-2 my-5">
              <p>Shuffle</p>
              <div>
                <AntSwitch
                  checked={isShuffleEnabled}
                  className="dark:accent-slate-400"
                  onChange={(e) => handleChange(e, "shuffle")}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-5 gap-y-2 my-5">
              <p>Make scheduled playlist public</p>
              <div>
                <AntSwitch
                  checked={isPlaylistPublicEnabled}
                  className="dark:accent-slate-400"
                  onChange={(e) => handleChange(e, "public")}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </div>
            </div>

            {/* <div className='grid grid-cols-2 gap-x-5 gap-y-2 my-5'>
              <p>Remind Me</p>
              <div>
              <AntSwitch  
                  checked={isRemindEnabled}
                   className="dark:accent-slate-400"
                  onChange={(e)=>handleChange(e,"remind")} 
                  inputProps={{ 'aria-label': 'ant design' }} />
              </div>
            </div> */}

            <div className="grid grid-cols-2 gap-x-5 gap-y-2 my-5">
              <p>Volume Level</p>
              <div>
                {/* <button onClick={()=>setIsMuted(pre=>!pre)} className="mx-2">{isMuted ? <VolumeOffIcon className='!text-base dark:text-white'/> : <VolumeUpIcon className='!text-base dark:text-white'/>}</button> */}
                <Slider
                  aria-label="Volume"
                  min={1}
                  max={10}
                  step={1}
                  value={volume}
                  className="!text-black !text-base !h-[2px] dark:!text-white"
                  onChange={handleVolumeChange}
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => handleSetting()}
            className="bg-[#F1B942] self-center text-white px-4 py-1 rounded-lg"
          >
            Save
          </button>
        </div>
      </Dialog>

      <Dialog
        visible={showEventModal}
        className={`p-5  ${
          isDark ? "dark" : ""
        } lg:h-auto w-[90vw] lg:w-[45vw] h-[50%] text-center !bg-white !opacity-1 dark:!bg-[#0D0D0D] drop-shadow-xl rounded-xl relative`}
        onHide={() => setShowEventModal()}
      >
        <HighlightOffIcon
          onClick={() => setShowEventModal(false)}
          className="cursor-pointer absolute top-5 right-5 dark:!text-white"
        />
        <div className="flex justify-between items-center gap-2 dark:!bg-[#0D0D0D] absolute top-[22px] right-[55px]">
          <CreateOutlinedIcon
            onClick={() => setIsCreateModal(true)}
            className="!text-[#A1A1A1] cursor-pointer  lg:!text-2xl !text-lg"
          />
          <div className="relative">
            <DeleteOutlinedIcon
              onClick={() => setIsDeleteModal((pre) => !pre)}
              className="lg:!text-[#A1A1A1] cursor-pointer lg:!text-2xl !text-lg"
            />
            {/* <ConfirmPopup/> */}
            {isDeleteModal && (
              <div className="absolute dark:!bg-[#0D0D0D] dark:border-[1px] dark:border-[#A1A1A1] top-6 lg:-right-[47px] -right-[92px] lg:top-10 w-80 py-5 px-5 drop-shadow-xl rounded-xl bg-white dark:text-white">
                <p>Do you want to delete this Event?</p>
                <div className="flex justify-center items-center gap-x-5 mt-5">
                  <button
                    className="bg-[#F1B942] text-white px-3 py-1 rounded-xl"
                    onClick={() => setIsDeleteModal(false)}
                  >
                    No
                  </button>
                  <button
                    className="bg-[#F1B942] text-white px-3 py-1 rounded-xl"
                    onClick={() => accept()}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          </div>
          <IosShareOutlinedIcon className="!text-[#A1A1A1] lg:!text-2xl !text-lg" />
          <SettingsIcon
            onClick={() => {
              setIsSettingModal(true);
            }}
            className="!text-[#A1A1A1] lg:!text-2xl !text-lg cursor-pointer"
          />
        </div>
        <div className="flex flex-col dark:!bg-[#0D0D0D] dark:!text-white justify-center h-full">
          <div className="flex justify-start items-center gap-x-1">
            <div className="bg-[#F1B942] lg:p-5 p-3 rounded-lg">
              <CalendarTodayOutlinedIcon className="!text-white" />
            </div>
            <div className="text-left">
              <p className="text-sm lg:text-base font-semibold text-black my-1 ml-1 dark:!text-white ">
                {formattedDate}
              </p>
              <p className="text-sm lg:text-base font-normal text-black my-1 ml-1 dark:!text-white ">
                {stTime} - {endTime}
              </p>
            </div>
          </div>
          <H220 className="!text-left mt-5  !text-sm lg:!text-base !text-black  ">
            {calendarEvents?.data?.events[eventIndex]
              ? calendarEvents?.data?.events[eventIndex]?.t_title
              : "No Event Title"}
          </H220>
          {/* <p className='!text-xs text-left lg:!text-sm !text-black dark:!text-white'>To be Played in (<span className='text-[#1AB700]'>{minutesLeft} min</span>)</p> */}
          {minutesLeft >= 0 && (
            <p className="!text-xs text-left lg:!text-sm !text-black dark:!text-white">
              To be Played in (
              <span className="text-[#1AB700]">{minutesLeft} min</span>)
            </p>
          )}
          <H220 className="!text-left mt-5 !font-bold !text-xs lg:!text-sm !text-black ">
            Playlists
          </H220>
          {calendarEvents?.data?.events[eventIndex]?.playlists?.length > 0 ? (
            <div className="max-h-40 overflow-y-auto">
              {calendarEvents?.data?.events[eventIndex]?.playlists?.map(
                (playlist, index) => {
                  return (
                    <Link
                      to={`/collection/playlists/${playlist?.v_code}?id=${playlist?.id}&globalid=undefined `}
                      key={index}
                      className="flex justify-start items-center bg-[#FAFAF8] dark:bg-[#131313]  py-4 px-4 rounded-xl cursor-pointer my-3"
                    >
                      <div className="w-12 h-12 rounded-lg ">
                        <img
                          src={playlist?.t_image}
                          alt=""
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                      <div className="text-left ml-4">
                        <p className="text-xs font-semibold my-2">
                          {playlist?.v_name}
                        </p>
                        <p className="text-xs font-normal my-2">
                          {playlist?.t_description}
                        </p>
                      </div>
                    </Link>
                  );
                }
              )}
            </div>
          ) : (
            <div className="!text-xs text-left lg:!text-sm !text-black dark:!text-white ">
              No playlists added
            </div>
          )}
        </div>
      </Dialog>

      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        // initialView="timeGridDay"
        initialView="dayGridMonth"
        //   weekends={false}
        headerToolbar={{
          left: "prev,next today timeGridWeek,timeGridDay,dayGridMonth", // Added 'today' button for better navigation
          center: "title",
          right: "customButton", // Allows switching between week and day view
          // right: '' // Allows switching between week and day view
        }}
        customButtons={{
          customButton: {
            text: " + Schedule session",
            click: () => {
              checkIsPremium();
            },
          },
        }}
        timeZone="local"
        events={formattedEvents}
        eventContent={renderEventContent}
        dateClick={handleDateClick}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={2}
        ref={calendarRef}
        eventDidMount={eventDidMount}
        validRange={{
          start: new Date().toISOString().split("T")[0], // Today's date in 'yyyy-mm-dd' format
        }}
      />
    </div>
  );
};

export default TimelineCalender;
