import { createSlice } from "@reduxjs/toolkit";

const rememberMe =
  localStorage.getItem("rememberMe") !== null
    ? JSON.parse(localStorage.getItem("rememberMe"))
    : true;

const authenticationToken = JSON.parse(
  localStorage.getItem("AuthToken") ?? sessionStorage.getItem("AuthToken")
);
const userDataLocal = JSON.parse(
  localStorage.getItem("UserData") ?? sessionStorage.getItem("UserData")
);
const profileDataLocal = JSON.parse(
  localStorage.getItem("ProfileData") ?? sessionStorage.getItem("ProfileData")
);

// const authenticationToken = JSON.parse(localStorage.getItem("AuthToken"))
// const userDataLocal = JSON.parse(localStorage.getItem("UserData"))
// const profileDataLocal = JSON.parse(localStorage.getItem("ProfileData"))
const initialState = {
  tokenStatus: authenticationToken ? true : false,
  userData: userDataLocal ? userDataLocal : null,
  profileData: profileDataLocal ? profileDataLocal : null,
  authModalStatus: "",
};

const authenticationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.tokenStatus = true;
      state.userData = action.payload;
      sessionStorage.setItem("UserData", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.tokenStatus = false;
      state.userData = null;
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("UserData");
      localStorage.removeItem("ProfileData");
      sessionStorage.removeItem("AuthToken");
      sessionStorage.removeItem("UserData");
      sessionStorage.removeItem("ProfileData");
      localStorage.removeItem("searchValue");

      // localStorage.removeItem('isDark')
    },
    signup: (state, action) => {
      state.tokenStatus = true;
      state.token = action.payload;
      // localStorage.setItem('UserData', JSON.stringify(action.payload))
      sessionStorage.setItem("UserData", JSON.stringify(action.payload));
    },
    profileDetail: (state, action) => {
      state.profileData = action.payload;
      // rememberMe // localStorage.setItem('ProfileData', JSON.stringify(action.payload))

      rememberMe
        ? localStorage.setItem("ProfileData", JSON.stringify(action.payload))
        : sessionStorage.setItem("ProfileData", JSON.stringify(action.payload));
    },

    aboutDetail: (state, action) => {
      console.log(action.payload, "action payload");

      switch (action.payload.status) {
        case "bio":
          state.profileData.t_bio = action?.payload?.txtbio;
          state.profileData.v_profession = action?.payload?.v_profession;
          break;
        case "instrument":
          state.profileData.Favourite.musicInstruments =
            action?.payload.musicInstruments;
          break;
        case "language":
          state.profileData.Favourite.languages = action.payload.language;
          break;
        case "geo":
          state.profileData.Favourite.geo_locations = action.payload.geo;
          break;
        case "genres":
          state.profileData.Favourite.genres = action.payload.genres;
          break;
        case "decade":
          state.profileData.Favourite.decades = action.payload.decade;
          break;
      }
    },
    handleAuthModalStatus: (state, action) => {
      state.authModalStatus = action.payload;
    },
  },
});

export const {
  login,
  logout,
  signup,
  profileDetail,
  handleAuthModalStatus,
  instrumentDetail,
  aboutDetail,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
