import React, { useEffect, useState } from 'react'
import BackToPage from '../view/components/common/BacktoPage/BackToPage'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import TopDetailCard from '../view/components/common/TopDetailCard/TopDetailCard';
import { useApi } from '../api/api';
import { useNavigate, useParams } from 'react-router';
import CommonProfileCard from '../view/components/ProfileComponents/CommonProfileCard';
import BioComponent from '../view/components/ProfileComponents/AboutComponents/BioComponent';
import CommonListing from '../view/components/collectionPagesComponents/CommonListing';
import Button1 from '../view/components/common/GlobalButtonLayout/Button1';
import DecadesComponent from '../view/components/ProfileComponents/AboutComponents/DecadesComponent';
import GeosComponent from '../view/components/ProfileComponents/AboutComponents/GeosComponent';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LanguageComponent from '../view/components/ProfileComponents/AboutComponents/LanguageComponent';
import InstrumentComponent from '../view/components/ProfileComponents/AboutComponents/InstrumentComponent';
import GenresComponent from '../view/components/ProfileComponents/AboutComponents/GenresComponent';
import { useDispatch, useSelector } from 'react-redux';
import { profileDetail } from '../store/authenticationSlice';
import ArtistsComponent from '../view/components/ProfileComponents/FavoritesComponents/ArtistsComponent';
import AlbumsComponent from '../view/components/ProfileComponents/FavoritesComponents/AlbumsComponent';
import TracksComponent from '../view/components/ProfileComponents/FavoritesComponents/TracksComponent';
import PlaylistsComponent from '../view/components/ProfileComponents/FavoritesComponents/PlaylistsComponent';


const GlobalProfileDetail = () => {
  const { handleGetRequest, handlePostRequest } = useApi();
  const { id } = useParams();
  const userData = useSelector((state) => state.auth.profileData);

  console.log("sgahc",id);
  
  const [nav,setNav] = useState(1)
  const [selectedWorldMapCountry, setSelectedWorldMapCountry] = useState([]);


  const [otherUserProfile, setOtherUserProfile] = useState()
  const [isEdit, setIsEdit] = useState()
  const navigate=useNavigate()
  
  useEffect(() => {


    if(id==userData.id){
     navigate('/profile') 
    }else{ (async () => {
      const playload = {
        user_id:id
      }
      const otherUserRes = await handlePostRequest(`profile-detail`,playload, false, false);
      console.log("otherUserRes", otherUserRes?.data?.data);
      setOtherUserProfile(otherUserRes?.data)
      }
    )();}
   
  }, [id]);

const dispatch = useDispatch()

  const handleDeletePlaylist = async(id) => {
      const playlistLikePayload = {
          playlist_ids:[id]
      }
      const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
      const profileDataReq = await handleGetRequest("profile-detail", false, true);
      dispatch(profileDetail(profileDataReq?.data));
  }

  console.log("otherUserProfile",otherUserProfile)
  return (
    <div>
       {/* <BackToPage pageRoute="/" backTo="Home"/> */}
       <div className="flex items-center gap-x-3 my-3 px-2 lg:px-0">
          <p className='lg:text-xl text-base font-semibold dark:text-white'>{otherUserProfile?.v_name?.length > 7 ? otherUserProfile?.v_name?.slice(0, 10) + "..." : otherUserProfile?.v_name}</p>
          <button className="flex justify-center gap-x-1 items-center py-1 lg:px-2 lg:py-2 dark:bg-[#232323] bg-[#E9E9E9] rounded-[50px] w-14 text-sm dark:text-white"><PeopleAltOutlinedIcon className='!text-base dark:!text-white'/> {otherUserProfile?.i_share_count}</button>
          <button className="flex justify-center gap-x-1 items-center py-1 lg:px-2 lg:py-2 dark:bg-[#232323] bg-[#E9E9E9] rounded-[50px] w-14 text-sm dark:text-white"><HeadphonesOutlinedIcon className='!text-base dark:!text-white'/> {otherUserProfile?.i_play_count}</button>
        </div>

        <div className="my-2">
          <TopDetailCard flag="global" matchRateShow={id==userData.id ? false:true} matchRate={otherUserProfile?.match_rate} requestStatus={otherUserProfile?.following_status} image={otherUserProfile?.t_image_path} titleName={otherUserProfile?.v_name} duration="" artistCount="9" decadeRange="" playListCount="23" genresCount="" platformsSynced={[]} totalTracks={otherUserProfile?.t_song_count} userId={otherUserProfile?.id} pillsData={otherUserProfile?.dataCounts} Top={otherUserProfile?.Top} setOtherUserProfile={setOtherUserProfile} profession={otherUserProfile?.v_profession}  follower_count={otherUserProfile?.i_follower_count} listener_count={otherUserProfile?.i_listener_count} is_own_profile={otherUserProfile?.is_own_profile}/>
        </div>

        {(!otherUserProfile?.is_private || otherUserProfile?.following_status==="accepted")?<div>
        <div className="grid lg:grid-cols-8 grid-cols-3  overflow-x-scroll  content-center text-center  text-[#312522] text-xl font-light gap-x-4 my-4 px-4">
                <p className={`cursor-pointer ${nav===1?"text-[#F1B942] dark:text-white text-sm lg:text-base font-bold":"text-sm lg:text-base dark:text-white"}`} onClick={()=>setNav(1)}>About</p>
                <p className={`cursor-pointer ${nav===2?"text-[#F1B942] dark:text-white text-sm lg:text-base  font-bold":"text-sm lg:text-base dark:text-white"}`} onClick={()=>setNav(2)}>Favorites</p>
                <p className={`cursor-pointer ${nav===3?"text-[#F1B942] dark:text-white text-sm lg:text-base font-bold":"text-sm lg:text-base dark:text-white"}`} onClick={()=>setNav(3)}>Playlists</p>
               
            </div>
            <div className="w-full h-[5px] bg-[#D9D9D9] grid  gap-x-4  lg:grid-cols-8 grid-cols-3 ">
                <div className={`${nav===1?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===2?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===3?"bg-[#F1B942]":""}`}></div>
            </div>
            {nav===1&&
            // <div>

            // <div>
            //    <CommonProfileCard name={otherUserProfile?.v_name  || "User Name"} >
            //     <BioComponent  bio={otherUserProfile?.t_bio} name={otherUserProfile?.v_name} />
            //   </CommonProfileCard>
            // </div>

            // <div className="grid lg:grid-cols-12 gap-x-4">
            //     <div className="lg:col-span-7 ">
            //         <CommonProfileCard name="Genres"   className="lg:min-h-64 overflow-scroll"   >
            //             <CommonListing imgHeight="!h-28" className="lg:!grid-cols-4 " listing={otherUserProfile?.Favourite?.genres} title="" />
            //         </CommonProfileCard>
            //     </div>

            //     <div className='grid lg:grid-cols-2 gap-x-5'>
            //            { profileData?.Favourite?.genres?.length>0 &&<CommonProfileCard name="Genres"><GenresComponent genresList={profileData?.Favourite?.genres}/></CommonProfileCard>}
            //             {profileData?.Favourite?.musicInstruments?.length>0 && <CommonProfileCard name="Favorite Instruments"><InstrumentComponent genresList={profileData?.Favourite?.musicInstruments} /></CommonProfileCard>}
            //         </div>
            //     <div className="lg:col-span-5 ">
            //         <CommonProfileCard name="Favorite Instruments" className="lg:min-h-64 overflow-scroll">
            //         <div className="flex justify-end items-center">
            //             <Button1 className="w-1/3 text-center">Done</Button1>
            //         </div>
            //         </CommonProfileCard>
            //     </div>
            // </div>

            // <CommonProfileCard name="Decades"  editicon={false} setIsEdit={setIsEdit} >
            //     <DecadesComponent decades={otherUserProfile?.user?.decades} />
            // </CommonProfileCard>

            // <CommonProfileCard name="Geos"  editicon={true} setIsEdit={setIsEdit} edit="geos">
            //     <GeosComponent setSelectedWorldMapCountry={setSelectedWorldMapCountry} selectedWorldMapCountry={otherUserProfile?.prefered_regions}/>
            // </CommonProfileCard>
            // </div>

            <div>
            <CommonProfileCard name={otherUserProfile?.v_name}><BioComponent t_bio={otherUserProfile?.t_bio} profession={otherUserProfile?.v_profession}/></CommonProfileCard>
            <div className='lg:grid lg:grid-cols-2 gap-x-5'>
               {/* { otherUserProfile?.Favourite?.genres?.length>0 &&<CommonProfileCard name="Genres"><GenresComponent genresList={otherUserProfile?.Favourite?.genres}/></CommonProfileCard>} */}
               { otherUserProfile?.Favourite?.genres?.length>0 &&<CommonProfileCard name="Genres"><GenresComponent genresList={otherUserProfile?.Favourite?.genres} nav={nav} flag="about"/></CommonProfileCard>}

                {otherUserProfile?.Favourite?.musicInstruments?.length>0 && <CommonProfileCard name="Favorite instruments"><InstrumentComponent flag="about" genresList={otherUserProfile?.Favourite?.musicInstruments} /></CommonProfileCard>}
            </div>
            {otherUserProfile?.Favourite?.decades?.length>0 && <CommonProfileCard name="Decades"><DecadesComponent  nav={nav} decades={otherUserProfile?.Favourite?.decades} /></CommonProfileCard>}
            {otherUserProfile?.Favourite?.geos?.length>0 && <CommonProfileCard name="Geos"><GeosComponent selectedWorldMapCountry={otherUserProfile?.Favourite?.geo_locations} nav={nav}/></CommonProfileCard>}
            <div className='lg:grid lg:grid-cols-2 gap-x-5'>
            {otherUserProfile?.Favourite?.languages?.length>0 && <CommonProfileCard name="Favorite languages"><LanguageComponent languageList={otherUserProfile?.Favourite?.languages}/></CommonProfileCard>}
                {/* <CommonProfileCard name="Selected  instrument and skill level"><SkillComponent/></CommonProfileCard> */}
            </div>
        </div>
            }

            
            {nav===2&&
                <div>
                    { otherUserProfile?.Favourite?.genres?.length>0 &&<CommonProfileCard name="Genres"><GenresComponent genresList={otherUserProfile?.Favourite?.genres} nav={nav} handleDeletePlaylist={handleDeletePlaylist} flag="profilePage"/></CommonProfileCard>}

                    {otherUserProfile?.Favourite?.decades?.length>0 && <CommonProfileCard name="Decades"><DecadesComponent  handleDeletePlaylist={handleDeletePlaylist} decades={otherUserProfile?.Favourite?.decades} nav={nav}/></CommonProfileCard>}

                    {otherUserProfile?.Favourite?.geos?.length>0 && <CommonProfileCard name="Geos"><GeosComponent selectedWorldMapCountry={otherUserProfile?.Favourite?.geos} nav={nav}  handleDeletePlaylist={handleDeletePlaylist}/></CommonProfileCard>}

                    <div className='lg:grid lg:grid-cols-2 gap-x-5'>
                        {otherUserProfile?.Favourite?.artists?.length>0 &&<CommonProfileCard name="Artists"><ArtistsComponent  handleDeletePlaylist={handleDeletePlaylist} albumList={otherUserProfile?.Favourite?.artists} /></CommonProfileCard>}

                       { otherUserProfile?.Favourite?.albums.length>0 &&<CommonProfileCard name="Albums"><AlbumsComponent  handleDeletePlaylist={handleDeletePlaylist} albumList={otherUserProfile?.Favourite?.albums}/></CommonProfileCard>}
                    </div>
                    {otherUserProfile?.Favourite?.songs?.length>0 &&<CommonProfileCard name="Tracks"><TracksComponent  albumList={otherUserProfile?.Favourite?.songs} /></CommonProfileCard>}

                    {otherUserProfile?.Favourite?.playlists?.length>0 && <CommonProfileCard name="Playlists"><PlaylistsComponent  handleDeletePlaylist={handleDeletePlaylist}  albumList={otherUserProfile?.Favourite?.playlists} /></CommonProfileCard>}
                </div>
            }

          {nav===3&&<CommonListing flag="collectionPage" listing={otherUserProfile?.playlists} title="playlists" fromprofile="playlists" />}
        </div>:<div className='flex flex-col justify-center items-center py-10'>
          <div>
            <div className='flex justify-center items-center'>
              <LockOutlinedIcon className='!text-[13rem] !text-[#F1B942]'/>
            </div>
            <p className='text-center lg:text-2xl text-base font-semibold my-2 dark:text-white'>This account is private</p>
            <p className='text-center text-base my-2 dark:text-white'>Follow this account to see their  music and 
            profile</p>
          </div>
          </div>}

    </div>
  )
}

export default GlobalProfileDetail