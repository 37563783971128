const CircularProgress = ({ percentage }) => {

    const strokeDasharray=100
    const strokeDashoffset = strokeDasharray-( percentage*strokeDasharray)/100;

    return (
        <div className="relative lg:size-20 size-16 ">
            <svg className="size-full rotate-30" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current hidden  lg:text-white"
                    strokeWidth="2"
                ></circle>
                <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-[#F1B942] "
                    strokeWidth="2"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                ></circle>
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 ">
              <div className="flex flex-col items-center ">
                <span className="text-center text-[12px] lg:text-sm font-bold  text-[#F1B942] font-sans ">{percentage}%</span>
                <span className="text-[12px] font-sans text-[#F1B942] lg:text-black dark:text-white">Matchs</span></div>
            </div>
        </div>
    );
};

export default CircularProgress;