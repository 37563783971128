import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../../../api/api";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Button1 from "../../common/GlobalButtonLayout/Button1";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "primereact/carousel";
import { aboutDetail } from "../../../../store/authenticationSlice";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import globalToast from "../../../../hooks/globalToast";

const DecadesUpdate = ({ openDecadesModal, setOpenDecadesModal }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const profileData = useSelector((state) => state.auth.profileData);

  const isDark = useSelector((state) => state.dark.isDarkValue);
  const { handleGetRequest, handlePostRequest } = useApi();

  const [selectedDecade, setSelectedDecade] = useState(
    profileData.Favourite.decades.map((i) => i.id)
  );
  const [decadeList, setDecadeList] = useState([]);
  const [page, setPage] = useState(1);
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 9,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 3,
      numScroll: 1,
    },
  ];

  useEffect(() => {
    (async () => {
      const decadesPlayload = {
        type: "decades",
        current_page: 1,
        per_page: 10000,
      };

      const decadesStepperRes = await handlePostRequest(
        `users/music-preferences/get`,
        decadesPlayload,
        false,
        true
      );
      console.log(decadeList, "decades=List");
      if (decadesStepperRes?.flag) {
        setDecadeList(decadesStepperRes?.data?.data);
      }
    })();
    setSelectedDecade(profileData.Favourite.decades.map((i) => i.id));
  }, [profileData]);

  const onDecadeChangeHandler = useCallback(
    (e, decade) => {
      // Prevent the default behavior of the click event
      e.preventDefault();

      setSelectedDecade((prev) => {
        // Check if the clicked decade is already in the array
        const isDecadeSelected = prev.includes(decade.id);

        if (isDecadeSelected) {
          // If selected, remove it from the array
          return prev.filter((item) => item !== decade.id);
        } else {
          // If not selected, add it to the array
          return [...prev, decade.id];
        }
      });
    },
    [setSelectedDecade]
  );

  const onSubmit = async () => {
    if (selectedDecade.length === 0) {
      globalToast("Select your preferred Decade", true);
      return;
    }
    const decadePayload = {
      scope: "decade",
      ids: selectedDecade,
    };

    const setlng = {
      status: "decade",
      decade: decadeList.filter((e) => selectedDecade.includes(e.id)),
    };
    const generatePlaylistRes = await handlePostRequest(
      `profile-edit-preferences`,
      decadePayload,
      true,
      true
    );
    dispatch(aboutDetail(setlng));
    setOpenDecadesModal(false);
  };

  const decadeTemplate = (product) => {
    return (
      <div
        onClick={(e) => onDecadeChangeHandler(e, product)}
        className="cursor-pointer"
      >
        <div className="relative mb-4 flex justify-center ">
          <div
            className={`w-[35px] h-[35px] ${
              selectedDecade?.includes(product?.id)
                ? "bg-[#F1B942] "
                : "bg-[#E9E9E9] "
            } rounded-full  z-50`}
          ></div>
          <div className="h-[2px] bg-[#FAFAF8] z-[-2] w-full absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
        </div>
        <div
          className={`text-center ${
            selectedDecade?.includes(product?.id)
              ? "text-[#F1B942] "
              : "text-black dark:text-white"
          }`}
        >
          {product?.v_year}`s
        </div>
      </div>
    );
  };

  return (
    <Dialog
      // fullScreen={fullScreen}
      open={openDecadesModal}
      maxWidth={"lg"}
      // onClose={handleCloseCreatePlaylistTrackModal}
      aria-labelledby="responsive-dialog-title"
      className={`lg:w-screen ${isDark ? "dark" : ""} `}
    >
      <div className="dark:bg-[#0D0D0D] py-3 px-3 lg:py-3 lg:px-8 lg:min-w-[1174px]    h-[100%] lg:h-auto dark:text-[#FFFFFF]">
        <div className="flex flex-col ">
          <div className="flex w-full justify-between gap-2 mb-4 items-center">
            {" "}
            <h6 className="text-black text-[16px] lg:text-[24px] font-medium   dark:text-white">
              What is your favorite generation of music ?
            </h6>
            <CancelOutlinedIcon
              onClick={() => setOpenDecadesModal(false)}
              className=" cursor-pointer dark:!text-[#A8A8A8] "
            />
          </div>

          <div className=" lg:px-10 px-3">
            {decadeList?.length > 0 && (
              <Carousel
                value={decadeList}
                nextIcon="pi pi-chevron-right custom-carousel-next-icon"
                prevIcon="pi pi-chevron-left custom-carousel-prev-icon"
                numVisible={8}
                numScroll={8}
                responsiveOptions={responsiveOptions}
                className="custom-carousel dark:text-white"
                circular
                autoplayInterval={3000}
                itemTemplate={decadeTemplate}
              />
            )}
          </div>

          <div className="flex justify-center items-center mt-[20px] lg:mt-[20px]">
            <div className="w-[104px]">
              <Button1
                type="submit"
                onClick={onSubmit}
                className=" mx-auto text-center text-sm ml-auto dark:hover:!text-[#F3C25A]"
              >
                Save
              </Button1>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DecadesUpdate;
