import { Outlet, useLocation } from "react-router"
import HeaderComponent from "../Header/HeaderComponent"
import FooterComponent from "../Footer/FooterComponent"
import SidebarComponent from "../Sidebar/SidebarComponent"
import { useDispatch, useSelector } from "react-redux"
import { handleCloseSidebar } from "../../../store/mobileSideBarSlice"
import { Backdrop } from "@mui/material"
import { ToastContainer } from "react-toastify"
import IframePlayer from "../common/TracksComponents/IframePlayer"
import ShareModalGlobal from "../common/ShareModalGlobal/ShareModalGlobal"
import { handleShareModal } from "../../../store/shareModalGlobalSlice"
import Stepper from "../Stepper/Stepper"
import { useEffect, useRef, useState } from "react"
import { handleScrollStatus, handleScrollValue } from "../../../store/scrollValueSlice"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useVisibilityChange from "../../../useVisibilityChange"
import { setupNotifications } from "../../../firebase"
import { sendNativeNotification, toastNotification } from "../../../notificationHelpers"
import { useApi } from "../../../api/api"
import CommonPlayer from "../common/Player/CommonPlayer"
import { handleBluetoothDevice } from "../../../store/bluethoothDeviceSlice"
import toast, { Toaster } from 'react-hot-toast';
import { handleAuthModalStatus, profileDetail } from "../../../store/authenticationSlice"
import { handleFollowerRequestList, handleFollowerUserList } from "../../../store/followerSlice"
import { handleFollowingRequestList, handleFollowingUserList } from "../../../store/followingSlice"
import { handleCreatePlaylistStatus } from "../../../store/createPlaylistSlice"
import BackToPage from "../common/BacktoPage/BackToPage"
import { playerOn, trackTitleSave } from "../../../store/trackSlice"
import { handleCountSongs } from "../../../store/trackLikeSlice"
import { handleBellNotificationCommunityPayload } from "../../../store/notificationBellSlice"
import { handleCurrentSongIndex, handlePlayerStatus } from "../../../store/playerSlice"
import { Link } from "react-router-dom"


function GlobalLayout(){

  const { handleGetRequest,handlePostRequest } = useApi();
    const MobileMenu = useSelector((state) => state.sidebar.isSideBar)
    const loadingstates = useSelector((state) => state.loader.isLoading);
    const isPlayer = useSelector((state) => state.tracks.isPlayer);
    const trackTitle = useSelector((state) => state.tracks.trackTitle);
    const trackData = useSelector((state) => state.tracks.trackData);
    const recommendData = useSelector((state) => state.tracks.recommendData);
    const isFilter = useSelector((state) => state.tracks.isFilter);
    const shareId = useSelector((state) => state.shareModal.isModalId);
    const isStepper = useSelector((state) => state.stepper.isStepper);
    const scrollValue = useSelector((state) => state.scroll.scrollValue);
    const isRecommend = useSelector((state) => state.tracks.isrecommended);
    const isDark = useSelector((state) => state.dark.isDarkValue);
    const playerStatus = useSelector((state) => state.player.playerOnOff);
    const push = useSelector((state) => state.push.payload);
    const [localVolume,setLocalVolume] = useState(0.4)

    const [isOnline, setIsOnline] = useState(navigator.onLine);
console.log(shareId,"shareoidd");
    const isForeground = useVisibilityChange();

    useEffect(() => {
      document.body.style.backgroundColor =isDark?"#0D0D0D" :"#ffffff";
    }, [isDark]);

    useEffect(()=>{
      if (MobileMenu) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    
    },[MobileMenu])

    
    // console.log("pusher",push);
 

    // useEffect(()=>{
    //   const pushNotiication  = JSON.parse(localStorage.getItem("ForegroundMessage"))
    //   console.log("pushNotiication",pushNotiication);
    // },[])

//  const dispatch = useDispatch();
const playNotificationSound = () => {
  const audio = new Audio('popupring.mp3');
  audio.play();
};

   

    useEffect(() => {
      const fetchFollowers = async () => {
        try {
          const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
          console.log("ResponseCommunity",notificationResponseCommunity);

          if(notificationResponseCommunity?.flag){
            dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
          }
        } catch (error) {
          console.error("Error fetching followers", error);
        }
      };
  
      fetchFollowers();
    }, []);

  useEffect(() => {
    const handleStorageChange = async () => {
      if (localStorage.getItem('ForegroundMessage') !== null) {
        console.log('ForegroundMessage key is present in localStorage.');

        const pushNotification = JSON.parse(localStorage.getItem("ForegroundMessage"));
        console.log("pushNotification", pushNotification);
        playNotificationSound();
       
        toast.custom((t) => (
          <Link to={pushNotification?.data?.url}
            className={`${
              t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex justify-start items-center">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={pushNotification?.notification?.image}
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                   <p className="text-sm font-medium text-black">
                      {pushNotification?.notification?.title}
                    </p>
                  
                  <p className="mt-1 text-sm text-black flex justify-start items-center font-semibold">
                    {pushNotification?.notification?.body}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </Link>
        ));

        try {
          const followerUserRes = await handleGetRequest(`followers`, false, false);
          console.log("followerUserRes", followerUserRes?.data);
          dispatch(handleFollowerUserList(followerUserRes?.data));
          const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,false)
          console.log("followingUserRes",followerUserRequestRes?.data);
          dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
          // const followingUserRes = await handleGetRequest(`followings`,false,false)
          // console.log("followingUserRes",followingUserRes?.data);
          // dispatch(handleFollowingUserList(followingUserRes?.data))
          // const followingpendingUserRes = await handleGetRequest(`pendingFollowings`,false,false)
          // console.log("followingUserRes",followingpendingUserRes?.data);
          // dispatch(handleFollowingRequestList(followingpendingUserRes?.data))
          // const profileDataReq = await handleGetRequest("profile-detail", false, false);
          // dispatch(profileDetail(profileDataReq?.data));

          const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
          console.log("ResponseCommunity",notificationResponseCommunity);
  
          if(notificationResponseCommunity?.flag){
          dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
          }
        } catch (error) {
          console.error("Error fetching followers:", error);
        }
      }
    };

    window.addEventListener('foregroundMessageChange', handleStorageChange);

    return () => {
      window.removeEventListener('foregroundMessageChange', handleStorageChange);
    };
  }, []);



    useEffect(() => {
      setupNotifications((message) => {
        const { title, body } = message;
        console.log("isForeground",message);
        if (isForeground) {
          // App is in the foreground, show toast notification
          toastNotification({
            title,
            description: body,
            status: "info",
          });
        } else {
          // App is in the background, show native notification
          sendNativeNotification({
            title,
            body,
          });
        }
      });
    }, []);

    useEffect(() => {
      const handleOnlineStatus = () => setIsOnline(true);
      const handleOfflineStatus = () => setIsOnline(false);
  
      window.addEventListener('online', handleOnlineStatus);
      window.addEventListener('offline', handleOfflineStatus);
  
      return () => {
        window.removeEventListener('online', handleOnlineStatus);
        window.removeEventListener('offline', handleOfflineStatus);
      };
    }, []);
 

    useEffect(()=>{
        const storedVolume = localStorage.getItem('volume');
        // console.log("storedVolume",storedVolume);

        if (storedVolume !== null && storedVolume !== undefined) {
            const volumeValue = parseInt(storedVolume, 10);
            console.log("storedVolume", volumeValue);
            setLocalVolume(parseFloat(storedVolume));
          } else {
            // Volume not found in local storage, return or handle the case as needed
            return;
          }
     
        // const volumeValue = parseInt(storedVolume, 10);
        // console.log("storedVolume",volumeValue);
        // setLocalVolume(parseFloat(storedVolume))
      },[])


    const dispatch = useDispatch()
    const handleMobileSidebar = () => {
        dispatch(handleCloseSidebar())
    }

    const handleShareModalClose = () => {
        dispatch(handleShareModal(""))
    }

    const [scrollY, setScrollY] = useState(0);

    // useEffect(() => {
    //   const handleScroll = () => {
    //     setScrollY(window.scrollY);
    //     dispatch(handleScrollValue(window.scrollY))
    //   };
  
    //   window.addEventListener("scroll", handleScroll);
  
    //   return () => {
    //     window.removeEventListener("scroll", handleScroll);
    //   };
    // }, []);


useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Check if the user has scrolled to the bottom
    if (scrollPosition + windowHeight >= documentHeight) {
      console.log("User has reached the bottom of the page.");
      dispatch(handleScrollStatus(true))

      // You can call your dispatch or other functions here when bottom is reached
      // dispatch(handleScrollValue(scrollPosition, true)); // Pass a flag for bottom detection?
    } else {
      // dispatch(handleScrollValue(scrollPosition, false)); // Normal scroll detection
      // dispatch(handleScrollStatus(false))
    }

    setScrollY(scrollPosition);
    dispatch(handleScrollValue(window.scrollY))

  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, [dispatch]);






    console.log("scrollY",scrollY);
    


        const scrollToTop = () => {
           window.scrollTo({
             top: 0,
             behavior: "smooth"
           });
        };


        // const [device, setDevice] = useState(null);
        // const [error, setError] = useState(null);
        // navigator.mediaDevices.enumerateDevices().then(function(devices) {
        //   var audioOutput = devices.find(device => device.kind === 'audiooutput');
        //   console.log('Default audio output:', audioOutput);

      
        // });

        const [audioDevices, setAudioDevices] = useState([]);

        // useEffect(() => {
        //   const getAudioDevices = async () => {
        //     try {
        //       const devices = await navigator.mediaDevices.enumerateDevices();
        //       const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
        //       setAudioDevices(audioOutputDevices);
        //     } catch (err) {
        //       console.error('Error accessing audio devices: ', err);
        //     }
        //   };
      
        //   getAudioDevices();
        // }, []);

        console.log("audioDevices",audioDevices);

        useEffect(() => {
          const getMediaPermissions = async () => {
            try {
              await navigator.mediaDevices.getUserMedia({ audio: true });
              const getAudioDevices = async () => {
                try {
                  const devices = await navigator.mediaDevices.enumerateDevices();
                  const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
                  setAudioDevices(audioOutputDevices);
                  dispatch(handleBluetoothDevice(audioOutputDevices[0]?.label))

                } catch (err) {
                  console.error('Error accessing audio devices: ', err);
                }
              };
              getAudioDevices();
            } catch (err) {
              console.error('Error accessing media devices: ', err);
            }
          };
        
          getMediaPermissions();
        }, []);
      
       
      
        useEffect(() => {
          const storedIsPlaying = localStorage.getItem('isPlaying');
          // const stored = localStorage.getItem('playingCurrentSong');
          // const playingCurrentSong = stored?JSON?.parse(stored):{};

          const stored = localStorage.getItem('playingCurrentSong');
          const playingCurrentSong = stored && stored !== "undefined" ? JSON.parse(stored) : {};

          if (storedIsPlaying !== null) {
            // dispatch(handleIsRecommended(false))
            // localStorage.setItem('playingCurrentSong',  JSON.stringify(stored))
            dispatch(handlePlayerStatus(playingCurrentSong?.e_platform));
            dispatch(handleCurrentSongIndex(0));
            // dispatch(handleCountSongs(countTracks.map(item =>
            //     item.id === id ? { ...item, play_count: item.play_count + 1 } : item
            // )))
           
            console.log("storedIsPlaying",playingCurrentSong);
            
          }
        }, []);


      

      
        // const requestDevice = async () => {
        //   try {
        //     const device = await navigator.bluetooth.requestDevice({
        //       filters: [{ services: ['battery_service'] }]
        //     });
        //     console.log("recommenddevice", device)
        //     setDevice(device);
        //     connectToDevice(device);
        //     dispatch(handleBluetoothDevice(device))
        //   } catch (error) {
        //     setError(error.message);
        //   }
        // };

        // useEffect(()=>{
        //   requestDevice()
        // },[])
        
        // const connectToDevice = async (device) => {
        //   try {
        //     const server = await device.gatt.connect();
        //     const service = await server.getPrimaryService('battery_service');
        //     const characteristic = await service.getCharacteristic('battery_level');
        //     const value = await characteristic.readValue();
        //     console.log(`Battery Level: ${value.getUint8(0)}%`);
        //   } catch (error) {
        //     setError(error.message);
        //   }
        // };


        // console.log("device",device);

        // useEffect(() => {
        //   const handleBackButton = (event) => {
        //     console.log("minimizeapp",window.location.pathname);

        //     if (window.location.pathname === '/' || window.location.pathname === '') {
        //       event.preventDefault();
        //       console.log("minimizeappsdcds");
        //       minimizeApp();
        //     }
        //   };
      
        //   const minimizeApp = () => {
        //     window.blur(); // Minimize the app
        //     console.log("minimize");
        //   };

        //   window.addEventListener('popstate', handleBackButton);
      
        //   return () => {
        //     window.removeEventListener('popstate', handleBackButton);
        //   };
        // }, []);

        // useEffect(() => {
        //   var addScript = document.createElement('script');
        //   addScript.setAttribute(
        //     'src',
        //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        //   );
        //   document.body.appendChild(addScript);
        //   window.googleTranslateElementInit = googleTranslateElementInit;
        // }, []);
      
        // const googleTranslateElementInit = () => {
        //   new window.google.translate.TranslateElement(
        //     {
        //       pageLanguage: 'en',
        //       includedLanguages: 'en,fr,ar', // include this for selected languages
        //       layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        //     },
        //     'google_translate_element'
        //   );
        // };
        const location = useLocation();
        useEffect(() => {
          dispatch(handleCreatePlaylistStatus(false));
        }, [location, dispatch]);


        
     
//  const scrollRef = useRef(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollRef.current) {
//         console.log("isEnd",scrollRef.current);

//         const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
//         if (scrollTop + clientHeight >= scrollHeight) {
//           console.log('Scrolled to the bottom!');
//           // Add your logic here when the user has scrolled to the bottom
//         }
//       }
//     };

//     const refCurrent = scrollRef.current;
//     if (refCurrent) {
//       refCurrent.addEventListener('scroll', handleScroll);
//     }

//     // Cleanup the event listener on component unmount
//     return () => {
//       if (refCurrent) {
//         refCurrent.removeEventListener('scroll', handleScroll);
//       }
//     };
//   }, []);

  
  
    return(

         <div  className={`h-[100%]  lg:h-auto  flex flex-col relative bg-[#FAFAF8] dark:bg-[#0D0D0D] lg:pr-8 ${isDark?"dark":""}`}>
          {/* { (!tokenStatus) && <div onClick={()=>dispatch(handleAuthModalStatus("login"))}  className="absolute top-0 w-full h-full bg-opacity-25 bg-slate-500 z-[100]"></div>} */}
      
            <Toaster
              position="bottom-center"
              reverseOrder={false}
            />
         {/* {!isOnline && toast.error("Please Check Your Internet Connection !!")} */}
         {!isOnline && <div className="bg-black opacity-50 fixed top-0 z-[99999] flex justify-center items-center text-white w-full h-full">
          </div>}
        { !isOnline && <p className="!bg-black !text-[#F1B942] z-[99999999] text-lg p-2 opacity-100 border-[2px] !border-[#F1B942]">Please Check Your Internet Connection !!</p>}
            <ToastContainer  className="z-[9999999999999999]"/>
            <ShareModalGlobal location={shareId} isglobal={true} open={shareId?true:false} handleClose={handleShareModalClose} />
             <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingstates}
                className="!z-[9999999999999999]"
            >
                <img src="/GIF-LOGO.gif" alt="" className="w-[100px] h-[100px] "/>
            </Backdrop>
            {MobileMenu&&<div className="w-full h-full bg-black bg-opacity-30 fixed z-[23] " onClick={()=>handleMobileSidebar()}></div>}
            {isStepper &&<Stepper/>}
           <HeaderComponent isFilter={isFilter}/>
           {location.pathname !== '/' && (
              <BackToPage pageRoute={""} backTo={`${""}`} />
            )}
           {/* <BackToPage pageRoute={""} backTo={`${""}`}/> */}
            <div className="relative">
              <SidebarComponent/>
            </div>
            <main className={`lg:w-[80%] lg:mb-[15%] mb-[43%] overflow-y-auto w-full ml-auto lg:h-[100%] h-[100%]  mt-[24%] md:mt-[10%] xl:mt-[6%] ${playerStatus ? "lg:!mb-[17%] !mb-[65%] ": ""} ${isFilter && playerStatus?"lg:!mb-[30%]":""}`}>
              <Outlet/>
            </main> 
            <div className="relative">
           
              <FooterComponent playerStatus={playerStatus}/>
            </div>
            {/* {isPlayer>-1&&<div>
                <IframePlayer isRecommend={isRecommend} songs={isRecommend?recommendData?.length>0?recommendData:trackData: trackData?trackData:[]} index={isPlayer} trackTitle={trackTitle} localVolume={localVolume}/>
            </div>} */}

            {playerStatus&&<div>
                <CommonPlayer platform={playerStatus} localVolume={localVolume}/>
            </div>}

            {scrollValue!==0 && <button onClick={scrollToTop} className={`fixed  z-[99999] right-4 bg-[#F1B942] hover:bg-[#F1B942] text-white font-bold py-1 px-2 rounded  ${playerStatus ? "lg:bottom-32 bottom-24": "bottom-4"}`}>
            <KeyboardArrowUpIcon className="!text-xl"/>
            </button>}
        </div> 
    )
}

export default GlobalLayout