import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BioUpdate from "./BioUpdateComponent";

const BioComponent = ({ t_bio, profession, showEdit }) => {
  const [openBioModal, setOpenBioModal] = useState(false);

  const handleOpenBioModal = () => {
    setOpenBioModal(true);
  };

  return (
    <div className="">
      <BioUpdate
        openBioModal={openBioModal}
        setOpenBioModal={setOpenBioModal}
        t_bio={t_bio}
      />
      <div className="flex justify-start flex-wrap items-center gap-x-3 my-4">
        <p className="lg:text-xl text-sm font-semibold dark:text-white">
          Pro Info:
        </p>
        {profession?.map((item, index) => {
          return (
            <button
              key={index}
              className="bg-[#F1B942] py-2 px-3 rounded-full text-white text-xs capitalize"
            >
              {item}
            </button>
          );
        })}
      </div>
      <p className="text-sm font-normal break-words dark:text-[#DDD1CE]">
        {t_bio ? t_bio : "Bio not found !!"}
      </p>
      {showEdit && (
        <div className={`absolute top-2  right-0  min-w-10  block`}>
          <button className="relative  w-5 h-5">
            <BorderColorOutlinedIcon
              onClick={handleOpenBioModal}
              className="!text-[#312522] dark:!text-[#A8A8A8] w-4 h-4 hover:!text-[#F1B942]"
            />
          </button>
        </div>
      )}{" "}
    </div>
  );
};

export default BioComponent;
