import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../../../api/api";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button1 from "../../common/GlobalButtonLayout/Button1";
import { useDispatch, useSelector } from "react-redux";
import { aboutDetail } from "../../../../store/authenticationSlice";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import globalToast from "../../../../hooks/globalToast";

const GenresUpdate = ({ openGenresModal, setOpenGenresModal }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const profileData = useSelector((state) => state.auth.profileData);

  const isDark = useSelector((state) => state.dark.isDarkValue);

  const [selectedCategoryList, setSelectedCategoryList] = useState(
    profileData.Favourite.genres
  );

  console.log(selectedCategoryList, "selectedList");
  const [page, setPage] = useState(1);
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  console.log(viewportHeight, "viewportHeight");
  const [genres, setGenres] = useState([]);
  const { handleGetRequest, handlePostRequest } = useApi();

  useEffect(() => {
    const savedGenres = localStorage.getItem("selectedGenres");
    if (savedGenres) {
      const parsedGenres = JSON.parse(savedGenres);
      setSelectedCategoryList(parsedGenres);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const genresPlayload = {
        type: "genres",
        current_page: page,
        per_page: viewportHeight < 1000 ? 10 : 12,
      };
      const genresStepperRes = await handlePostRequest(
        `users/music-preferences/get`,
        genresPlayload,
        false,
        true
      );
      if (genresStepperRes?.flag) {
        setGenres(genresStepperRes);
      }
    })();
    setSelectedCategoryList(profileData.Favourite.genres);
  }, [page, profileData]);
  const handleCloseCreatePlaylistTrackModal = () => {
    setOpenGenresModal(false);
  };
  const onSelectedCategoryChangeHandler = useCallback(
    (event, categoryListData) => {
      const { id } = categoryListData;
      setSelectedCategoryList((prevSelectedCategories) => {
        if (event.target.checked) {
          // Add the selected genre if checked
          return [...prevSelectedCategories, categoryListData];
        } else {
          // Remove the deselected genre if unchecked
          return prevSelectedCategories.filter((genre) => genre.id !== id);
        }
      });
    },
    []
  );

  const onSubmit = async () => {
    if (selectedCategoryList.length === 0) {
      globalToast("Please select at least one music genre.", true);
      return;
    }

    const instruPayload = {
      scope: "genre",
      ids: selectedCategoryList.map((i) => i.id),
    };

    const setlng = {
      status: "genres",
      genres: selectedCategoryList,
    };
    const generatePlaylistRes = await handlePostRequest(
      `profile-edit-preferences`,
      instruPayload,
      true,
      true
    );
    dispatch(aboutDetail(setlng));
    setOpenGenresModal(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openGenresModal}
        maxWidth={"lg"}
        // onClose={handleCloseCreatePlaylistTrackModal}
        aria-labelledby="responsive-dialog-title"
        className={`lg:w-screen ${isDark ? "dark" : ""} `}
      >
        <div className=" dark:bg-black  py-3 px-4 lg:px-8 lg:min-w-[920px] lg:max-w-[1120px]   lg:h-auto dark:text-[#FFFFFF]">
          <div className="flex flex-col relative">
            <div className="flex flex-col w-full sticky top-0 py-2 z-50 bg-white dark:bg-black  ">
              <div className="flex w-full justify-between mb-2 items-center">
                {" "}
                <h6 className="text-black text-[16px] lg:text-[24px] dark:text-white font-medium  ">
                  {" "}
                  What music style do you like ?
                </h6>
                <CancelOutlinedIcon
                  onClick={() => handleCloseCreatePlaylistTrackModal()}
                  className=" cursor-pointer dark:!text-[#A8A8A8] "
                />
              </div>
            </div>
            <div className="flex justify-between dark:bg-black  overflow-scroll  items-center  flex-col lg:flex-row">
              <button
                className="hidden lg:block"
                onClick={() =>
                  page > 1
                    ? setPage((pre) => pre - 1)
                    : setPage(genres?.data?.last_page)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="31"
                  viewBox="0 0 17 31"
                  fill="none"
                >
                  <path
                    d="M15 2L1.5 15.5L15 29"
                    stroke="#D8D8D8"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              <div
                className={`${viewportHeight < 670 ? " lg:h-[400px] " : " "}`}
              >
                <div
                  className={`grid grid-cols-2 pb-14 lg:pb-1  dark:bg-black  gap-y-2  p-2 ${
                    viewportHeight < 1000 ? "lg:grid-cols-5" : "lg:grid-cols-4 "
                  }   overflow-scroll`}
                >
                  {genres?.data?.data?.map((item) => {
                    return (
                      <div key={item?.id} className="p-2 ">
                        <div className="flex justify-between my-2 items-center h-10">
                          <p className="dark:text-white text-[14px] lg:text-[16px] ">
                            {item?.t_genres}
                          </p>
                          <label class="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              checked={selectedCategoryList.some(
                                (i) => i.id == item.id
                              )}
                              value=""
                              class="sr-only peer"
                              onChange={(event) =>
                                onSelectedCategoryChangeHandler(event, item)
                              }
                            />
                            <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F1B942] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F1B942]"></div>
                          </label>
                        </div>
                        <div className="w-full h-[150px] rounded-md">
                          <img
                            src={item?.t_image}
                            alt=""
                            className="w-full object-cover rounded-md h-full"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <button
                className="hidden lg:block"
                onClick={() =>
                  page < genres?.data?.last_page
                    ? setPage((pre) => pre + 1)
                    : setPage(1)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="31"
                  viewBox="0 0 17 31"
                  fill="none"
                >
                  <path
                    d="M2 2L15.5 15.5L2 29"
                    stroke="#D8D8D8"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div className="lg:hidden dark:bg-black bg-white z-50 fixed bottom-0 justify-center  right-0 left-0  flex items-center gap-5">
                <button
                  className=" lg:hidden"
                  onClick={() =>
                    page > 1
                      ? setPage((pre) => pre - 1)
                      : setPage(genres?.data?.last_page)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="31"
                    viewBox="0 0 17 31"
                    fill="none"
                  >
                    <path
                      d="M15 2L1.5 15.5L15 29"
                      stroke="#D8D8D8"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div className="w-[104px]">
                  <Button1
                    type="submit"
                    onClick={onSubmit}
                    className=" w-[100px] mx-auto text-center text-sm ml-auto dark:hover:!text-[#F3C25A]"
                  >
                    Save
                  </Button1>{" "}
                </div>
                <button
                  className=" lg:block"
                  onClick={() =>
                    page < genres?.data?.last_page
                      ? setPage((pre) => pre + 1)
                      : setPage(1)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="31"
                    viewBox="0 0 17 31"
                    fill="none"
                  >
                    <path
                      d="M2 2L15.5 15.5L2 29"
                      stroke="#D8D8D8"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="lg:flex bg-white dark:bg-black    w-full justify-center hidden items-center mt-[10px] lg:mt-[20px]">
              <div className="w-[104px]">
                <Button1
                  type="submit"
                  onClick={onSubmit}
                  className=" w-[100px] mx-auto text-center text-sm ml-auto dark:hover:!text-[#F3C25A]"
                >
                  Save
                </Button1>{" "}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default GenresUpdate;
