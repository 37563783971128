import { useEffect, useState } from "react";
import { useApi } from "../../api/api";
import PricingCards from "../../view/components/PremiumComponents/PricingCards";
import Button2 from "../../view/components/common/GlobalButtonLayout/Button2";
import H130 from "../../view/components/common/Typograpgy/H130";
import H220 from "../../view/components/common/Typograpgy/H220";
import P16 from "../../view/components/common/Typograpgy/P16";
import P20 from "../../view/components/common/Typograpgy/P20";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileDetail } from "../../store/authenticationSlice";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";

const Subscription = () => {
  const dispatch = useDispatch();
  const { handleGetRequest, handlePostRequest } = useApi();
  const [SubscriptionData, setSubscriptionData] = useState();
  const [isFreeSubActive, setIsFreeSubActive] = useState();
  const [changeSubscription, setChangeSubscription] = useState(null);
  // const ProfileData = JSON.parse(localStorage.getItem("ProfileData"))
  // console.log("ProfileData",ProfileData?.user?.is_premium_subscriber);
  const ProfileData = useSelector((state) => state.auth.profileData);
  useEffect(() => {
    // Skip the first render
    (async () => {
      const subscriptionRes = await handleGetRequest(
        `subscriptions`,
        false,
        true
      );
      if (subscriptionRes?.flag) {
        console.log("subscriptionRes", subscriptionRes);
        setSubscriptionData(subscriptionRes?.data);
      }
    })();
  }, []);

  const checkSubscriptionUpgradeDowngrade = (plan) => {
    if (!plan.is_subscription_active) {
      return; // If subscription is not active, no need for comparison
    }

    const currentPrice = parseFloat(plan.v_plan_price);

    const comparisonResult = SubscriptionData.filter(
      (p) => p.is_subscription_active && p.id !== plan.id
    ).map((p) => {
      const otherPrice = parseFloat(p.v_discounted_price || p.v_plan_price);
      if (currentPrice < otherPrice) {
        return { action: "Upgrade" };
      } else if (currentPrice > otherPrice) {
        return { action: "Downgrade" };
      }
    });
  };

  const deactivatePlan = async (code) => {
    const payload = {
      v_code: code,
    };
    const deactivateRes = await handlePostRequest(
      `payment/deactivate`,
      payload,
      false,
      true
    );
    if (deactivateRes?.flag) {
      console.log("deactivateRes", deactivateRes);
      const subscriptionRes = await handleGetRequest(
        `subscriptions`,
        false,
        true
      );
      if (subscriptionRes?.flag) {
        console.log("subscriptionRes", subscriptionRes);
        setSubscriptionData(subscriptionRes?.data);
      }
      const profileDataReq = await handleGetRequest(
        "profile-detail",
        false,
        true
      );
      dispatch(profileDetail(profileDataReq?.data));
    }
  };

  const upgradePlan = async () => {
    const upgradeRes = await handleGetRequest(
      `subscription?action=upgrade`,
      false,
      true
    );
    if (Object.keys(upgradeRes?.subscription).length != 0) {
      setChangeSubscription(upgradeRes?.subscription);
    }

    console.log("upgradeRes", upgradeRes);
  };
  const downgradePlan = async () => {
    const downgradeRes = await handleGetRequest(
      `subscription?action=downgrade`,
      false,
      true
    );
    setChangeSubscription(downgradeRes?.subscription);
    console.log("downgradeRes", downgradeRes);
  };

  console.log("SubscriProfileDataptionData", ProfileData?.user?.created_at);
  const date = new Date(ProfileData?.user?.created_at);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return (
    <>
      <div className="">
        {/* <BackToPage pageRoute="/" backTo="Home"/> */}
        <div className="px-3 lg:px-0">
          <H220 className="!mb-7 ">Manage subscription</H220>
        </div>
        <div className="drop-shadow-xl rounded-lg w-full bg-white my-2 py-10 px-8 text-white dark:bg-[#151515]">
          {!ProfileData?.user?.is_premium_subscriber && (
            <P20 className="text-center dark:text-white font-semibold text-xl lg:text-xl">
              Upgrade to unlock paid features
            </P20>
          )}
          <div className=" gap-x-4">
            {SubscriptionData?.map((item) => {
              return (
                <div>
                  {item?.is_subscription_active && (
                    <PricingCards
                      data={SubscriptionData}
                      expireDate={item?.d_end_date}
                      vcode={item?.v_code}
                      title={item?.v_plan_name}
                      discountPrice={item?.v_discounted_price}
                      subtitle={item?.v_plan_price}
                      buttonColor="bg-[#CDCDCD]"
                      isactive={item?.is_subscription_active}
                      className="bg-[#F1F1F1]"
                      featureList={item?.t_features}
                      bgColor={item?.v_color_code}
                      deactivatePlan={deactivatePlan}
                      isSubscription={true}
                      pdf={item?.v_invoice_path}
                      upgradePlan={upgradePlan}
                      downgradePlan={downgradePlan}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {!ProfileData?.user?.is_premium_subscriber && (
          <div className="drop-shadow-xl rounded-lg w-full bg-white my-2 py-10 px-8 text-white dark:bg-[#151515]">
            <div>
              <div
                className={`rounded-lg py-5 text-center my-3 text-black dark:bg-[#1B1B1B]`}
              >
                <p
                  className={`text-3xl lg:text-[46px] font-semibold  mt-4 dark:text-white`}
                >
                  CuriousHit free
                </p>
                <p className={`lg:my-4 my-2 dark:text-white py-2 `}>
                  Signup - date: {formattedDate}
                </p>
                {/* <ul className="my-2">
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
            </ul> */}
                <div className="my-5">
                  {
                    <Link
                      to={`#`}
                      className={`min-w-[150px] my-3 px-3 py-2 text-white bg-[#CDCDCD]  rounded-[50px] `}
                    >
                      Free
                    </Link>
                  }
                </div>
              </div>
              {
                <div className="flex mt-4 justify-center items-center gap-x-2 px-3 lg:gap-x-4 ">
                  <div>
                    <Button2 className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#FFAD00] text-xs lg:text-base !text-center  lg:!w-40 lg:!h-12">
                      Activated
                    </Button2>
                  </div>
                  <div>
                    <Link to="/premium">
                      {" "}
                      <Button2 className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#FFAD00] text-xs lg:text-base !text-center  lg:!w-40 lg:!h-12">
                        {" "}
                        Join premium{" "}
                      </Button2>
                    </Link>
                  </div>
                </div>
              }
            </div>
          </div>
        )}

        {changeSubscription && (
          <PricingCards
            vcode={changeSubscription?.v_code}
            title={changeSubscription?.v_plan_name}
            discountPrice={changeSubscription?.v_discounted_price}
            subtitle={changeSubscription?.v_plan_price}
            buttonColor="bg-[#CDCDCD]"
            className="bg-[#F1F1F1]"
            featureList={changeSubscription?.t_features}
            isSubscription={false}
            bgColor={changeSubscription?.v_color_code}
            upgradePlan={upgradePlan}
            downgradePlan={downgradePlan}
          />
        )}
      </div>
    </>
  );
};

export default Subscription;
