import { Link } from "react-router-dom";
import Button1 from "../common/GlobalButtonLayout/Button1";
import Button2 from "../common/GlobalButtonLayout/Button2";
import H130 from "../common/Typograpgy/H130";
import { useEffect, useRef, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import axios from "axios"; // Import axios library
import { useApi } from "../../../api/api";
import Tracks from "../common/TracksComponents/Tracks";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { trackDetail } from "../../../store/trackSlice";
import { handleCloseLoader, handleOpenLoader } from "../../../store/loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { parseBlob } from "music-metadata-browser";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "react-toastify/dist/ReactToastify.css";
import {
  handleCreatePlaylistStatus,
  handleGeneratePlaylistStatus,
} from "../../../store/createPlaylistSlice";
import globalToast from "../../../hooks/globalToast";

const Upload = ({
  setUploadSongsData,
  uploadSongsData,
  setSort,
  type,
  setType,
  setSearchQuery,
  searchQuery,
  setIsliked,
  setSongId,
  setSongDeleteId,
  setNav,
  setUploadStep,
  uploadStep,
  setIsFilterApplied,
  setFilter,
}) => {
  console.log("uploadStep", uploadStep);
  const create = useSelector((state) => state.createplaylist.isCreatePlaylist);
  const userData = useSelector((state) => state.auth.profileData);
  const isDark = useSelector((state) => state.dark.isDarkValue);

  const jsmediatags = window.jsmediatags;
  const dispatch = useDispatch();
  const authenticationToken = JSON.parse(localStorage.getItem("AuthToken"));
  const { handleGetRequest } = useApi();
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const trackData = useSelector((state) => state.tracks.trackData);
  const sectionRef = useRef(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const scrollToSection = () => {
    sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [metadata, setMetadata] = useState(null);

  // useEffect(()=>{
  //     !trackData?.length>0 && setUploadStep(1)
  // },[])
  const [isCreateModal, setIsCreateModal] = useState(false);

  const [isPremiumModal, setisPremiumModal] = useState(false);

  const checkIsPremium = () => {
    if (!userData?.user?.is_premium_subscriber) {
      setisPremiumModal(true);
      return;
    }
    if (userData?.user?.current_subscription?.i_size_limit > userData?.user?.i_size) {
      setisPremiumModal(true);
      return;
    }
    // if (
    //   userData?.user?.current_subscription?.v_plan_price == 0 &&
    //   userData?.t_song_count > 9
    // ) {
    //   setisPremiumModal(true);
    //   return;
    // }
    setIsCreateModal(true);
    setUploadStep(2);
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles?.length <= 2) {
      setFiles(selectedFiles);
      setUploadStep(3);
      setErrorMessage("");

      selectedFiles.forEach((file) => {
        jsmediatags.read(file, {
          onSuccess: function (tag) {
            console.log("Metadata for", file.name, ":", tag.tags);
            setMetadata(tag.tags);
            // Here you can handle the metadata as needed
            // For example, you might want to display it or store it in state
          },
          onError: function (error) {
            console.log("Error reading metadata for", file.name, ":", error);
            // Handle errors, e.g., by setting an error message
          },
        });
      });
    } else {
      setErrorMessage("Please select only up to 2 files.");
    }
  };

  console.log("metadata", metadata);

  useEffect(() => {
    // Reset the cancel token source when the component mounts or when necessary
    setCancelTokenSource(axios.CancelToken.source());
    return () => {
      // Cancel the request if the component unmounts
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
    };
  }, []);

  const handleUpload = async () => {
    setUploadStep(4);
    // console.log("musiccccc",files[0].name);
    // if (files[0].name.includes(".mp3") || files[0].name.includes(".mp4")) {
    //     console.log("File is an MP3 or MP4.");
    // } else {
    //     console.log("File is not an MP3 or MP4.");
    //     globalToast("File is not an MP3 or MP4.",true)

    //     setUploadStep(2)
    //     return;
    // }
    const formData = new FormData();
    formData.append(`music_files`, files[0]);
    formData.append(`i_upload_count`, files?.length);
    formData.append(`i_year`, metadata.year ? metadata.year : 0);

    try {
      const uploadRes = await axios.post(
        "https://curioushit.nevinainfotech.in/api/v1.0/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${authenticationToken} `,
            "Content-Type": "multipart/form-data",
          },
          cancelToken: cancelTokenSource.token, // Pass the cancel token here
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            if (percentCompleted > 99) {
              dispatch(handleOpenLoader());
            }
          },
        }
      );

      if (!uploadRes?.data?.flag) {
        // toast.error(uploadRes?.data?.msg);
        globalToast(uploadRes?.data?.msg, true);
        setUploadStep(2);
      }

      console.log("uploadRes", uploadRes);
      if (uploadRes?.data?.flag) {
        const uploadTracksRes = await handleGetRequest(
          `track?e_platform_name=default`,
          false,
          true
        );
        if (uploadTracksRes?.flag) {
          setUploadSongsData(uploadTracksRes?.data);
          dispatch(trackDetail(uploadTracksRes?.data?.data));
        }
        setIsUploading(false);
        setUploadStep(5);
        setIsUploaded(true);
        const uploadedFile = files[0]; // Assuming only one file is uploaded

        // Handle successful upload
      }
      dispatch(handleCloseLoader());
    } catch (error) {
      console.error("Upload Error:", error?.message);
      // toast.error(error);
      globalToast(
        error?.message ? error?.message : "Something went wrong !!",
        true
      );
      setUploadStep(2);
      dispatch(handleCloseLoader());

      // Handle upload error
    } finally {
      setIsUploading(false);
      dispatch(handleCloseLoader());
    }
  };
  const handleCancelUpload = () => {
    // Step 3: Create a cancel function
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload canceled by the user.");
      setFiles([]);
      setCancelTokenSource(axios.CancelToken.source());
      return () => {
        // Cancel the request if the component unmounts
        if (cancelTokenSource) {
          cancelTokenSource.cancel();
        }
      };
    }
  };

  console.log("files", files);

  // useEffect(()=>{
  //     dispatch(handleGeneratePlaylistStatus(false))
  // },[])

  return (
    <div className="mt-5">
      <Dialog
        visible={isPremiumModal}
        className={`p-7 lg:h-auto w-[90vw] lg:w-[45vw] h-auto text-center !bg-white !opacity-1 ${
          isDark ? "dark" : ""
        } dark:!bg-[#151515] drop-shadow-xl rounded-xl `}
        onHide={() => setIsCreateModal(false)}
      >
        <HighlightOffIcon
          onClick={() => setisPremiumModal(false)}
          className="cursor-pointer absolute top-3 right-3 dark:!text-white"
        />
        <div className="flex flex-col justify-center h-full   py-10">
          <h2 className=" text-base lg:text-2xl font-semibold text-center dark:text-white">
            Upgrade to premium services
          </h2>
          <div className="flex justify-center items-center mt-10">
            <Link to="/premium">
              <Button1 className="dark:hover:!text-white">Upgrade</Button1>
            </Link>
          </div>
        </div>
      </Dialog>
      {uploadStep === 0 || uploadStep === 1 ? (
        <Button2
          className="!text-center !w-[40%] lg:!w-[15%] dark:text-[#F1B942]"
          onClick={() => checkIsPremium()}
        >
          Upload
        </Button2>
      ) : uploadStep === 3 ? (
        <Button2
          onClick={() => {
            setUploadStep(2);
            handleCancelUpload();
            setFiles([]);
          }}
          className="!text-center !w-[40%] lg:!w-[15%] dark:text-[#F1B942]"
        >
          <HighlightOffOutlinedIcon className="" />
          Cancel
        </Button2>
      ) : uploadStep === 4 ? (
        <Button2
          onClick={() => {
            setUploadStep(2);
            handleCancelUpload();
          }}
          className="!text-center !w-[40%] lg:!w-[15%] dark:text-[#F1B942]"
        >
          <HighlightOffOutlinedIcon className="dark:!text-white" /> Cancel
        </Button2>
      ) : (
        <Button2
          className="!text-center !w-[40%] lg:!w-[15%] dark:text-[#F1B942] text-sm lg:text-base"
          onClick={() => checkIsPremium()}
        >
          Upload
        </Button2>
      )}
      {!create ? (
        <Button2
          className="!text-center !w-[40%] mx-5 lg:!w-[15%] dark:text-[#F1B942] !text-sm lg:!text-base"
          onClick={() => dispatch(handleCreatePlaylistStatus(true))}
        >
          Generate playlist
        </Button2>
      ) : (
        <Button2
          className="!text-center !w-[40%] mx-5 lg:!w-[15%] dark:text-[#F1B942] text-sm lg:text-base"
          onClick={() => dispatch(handleGeneratePlaylistStatus(true))}
        >
          Generate
        </Button2>
      )}

      {uploadStep === 1 && searchQuery === "" && type === "all" && (
        <div className="w-full  my-3 mt-14 flex flex-col justify-center items-center bg-white h-auto py-10 dark:bg-[#0D0D0D] rounded-2xl">
          <div className={`px-3 text-center `}>
            <p className="lg:text-[27px] text-lg font-semibold dark:text-[#A1A1A1]">
              No Tracks uploaded yet! To upload your tracks
              <span
                className="text-[#F1B942] mx-2 cursor-pointer"
                onClick={() => checkIsPremium()}
              >
                click
              </span>
              here{" "}
            </p>
          </div>
        </div>
      )}

      {uploadStep === 2 && (
        <div className="w-full my-3 mt-14 flex flex-col justify-center items-center  bg-white h-auto py-10 px-5 dark:bg-[#151515] rounded-xl">
          <div className="">
            <H130 className="text-center text-lg lg:text-[32px] dark:!text-[#FFFFFF] ">
              Upload your music, records, live performance videos
            </H130>
            <div className="flex justify-center items-center">
              <label
                htmlFor="music-upload"
                className="lg:!w-[60%] !w-[80%] mx-auto bg-[#F4AE45] cursor-pointer rounded-[33px] px-3 py-2 lg:px-5 lg:py-3 mt-10"
              >
                <div className="flex justify-between items-center w-full">
                  <div className="bg-[#D8D8D8] px-3 py-2 rounded-[33px] dark:bg-[#151515] dark:text-[#FFFFFF]">
                    Choose files
                  </div>{" "}
                  <div className="text-white text-xs dark:text-[#151515] ">
                    No file chosen !
                  </div>
                </div>
                <input
                  id="music-upload"
                  type="file"
                  accept=".mp3,.mp4"
                  // accept="audio/mp3"
                  className="hidden"
                  onChange={handleFileChange}
                  multiple
                />
              </label>
              {errorMessage && (
                <div className="mt-4 text-red-600 hidden lg:block">
                  {errorMessage}
                </div>
              )}
            </div>
            {errorMessage && (
              <div className="mt-4 text-red-600 text-center block lg:hidden">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}

      {uploadStep === 3 && (
        <div className="w-full my-3 mt-14 flex flex-col justify-center items-center bg-white h-auto py-10 dark:bg-[#151515] rounded-xl">
          <div className="mt-4">
            <H130 className="text-center text-lg lg:text-[32px] my-4 dark:text-white">{`Upload ${files?.length} files to this site?`}</H130>
            <div className="flex justify-center items-center gap-x-4 mt-14">
              <Button2
                onClick={() => handleUpload()}
                className="lg:!w-[60%] !w-[90%] !text-[#F1B942] px-3 lg:px-0 text-center py-2 hover:!text-white dark:hover:!text-white "
              >
                Upload
              </Button2>
              {/* <Button2 onClick={() => {setUploadStep(2); handleCancelUpload()}} className="lg:!w-[60%] !w-[80%] !text-[#F1B942] hover:!text-white text-center flex justify-center items-center gap-x-1 px-3 py-2"><HighlightOffOutlinedIcon className="" />Cancel</Button2> */}
            </div>
          </div>
        </div>
      )}

      {uploadStep === 4 && (
        <div className="w-full my-3 mt-14 flex flex-col justify-center items-center bg-white h-auto py-10 px-5 dark:bg-[#151515]">
          <div className="mt-4 px-4">
            <H130 className="text-center text-lg lg:text-[32px] dark:text-white">
              Uploading your music...
            </H130>
            <div className="w-full bg-gray-200 mt-8">
              <div
                className="bg-[#F1B942] flex justify-between items-center text-xs leading-none py-[1px] text-center text-white px-5 "
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <div className="flex justify-between items-center text-xs mt-1 lg:text-lg dark:text-white">
              <div>
                {uploadProgress === 100 ? 1 : 0} of {files?.length} Tracks
              </div>
              <div>{uploadProgress}%</div>
            </div>
            <div className="flex justify-start items-center my-4 dark:text-white">
              <div>
                <div className="line-clamp-1  text-clip">
                  <span className="text-base font-bold ">Song title : </span>
                  {metadata?.title}
                </div>
                <div className="line-clamp-1  text-clip">
                  <span className="text-base font-bold  ">Artist name : </span>
                  {metadata?.artist}
                </div>
                <div className="line-clamp-1 text-clip">
                  <span className="text-base font-bold  ">Album name: </span>
                  {metadata?.album}
                </div>
                <div>
                  <span className="text-base font-bold ">Year: </span>
                  {metadata?.year}
                </div>
                <div className=" line-clamp-1 text-clip">
                  <span className="text-base font-bold ">Genre: </span>
                  {metadata?.genre}
                </div>
                {/* <div><span className="text-base font-bold">Decades:</span>{{metadata?.artist}}</div>
                    <div><span className="text-base font-bold">Geos:</span>{{metadata?.artist}}</div> */}
              </div>
            </div>
            <div className="flex justify-center items-center">
              {/* <Button2 onClick={() => {setUploadStep(2); handleCancelUpload()}} className=" !w-[50%] text-center dark:text-white"><HighlightOffOutlinedIcon className="dark:!text-white"/> Cancel</Button2> */}
            </div>
          </div>
        </div>
      )}

      {uploadStep === 5 && (
        <div className="w-full my-3 mt-14 flex flex-col justify-center items-center  bg-white h-auto py-10 dark:bg-[#151515] rounded-2xl">
          <div className="mt-4">
            <H130 className="text-center text-lg lg:text-[32px] dark:text-white">
              Upload complete
            </H130>
            <div className="flex justify-center items-center gap-x-2 my-10 dark:text-white">
              <p>{files?.length} Tracks</p>
              {/* <p onClick={scrollToSection} className="cursor-pointer"><MoreVertIcon/></p>
                    <button><ModeEditOutlineIcon/>Edit</button> */}
            </div>
            <div className="flex justify-center items-center gap-x-4 ">
              <Link
                to="/collection/tracks"
                onClick={() => setUploadStep()}
                className="border-[#F1B942] border-[2px] px-4 py-2 rounded-[50px] text-[#F1B942] hover:bg-[#F1B942] hover:text-white"
              >
                My collection
              </Link>
              <button
                onClick={() => {
                  setNav(1);
                  setUploadStep();
                }}
                className="border-[#F1B942] border-[2px] px-4 py-2 rounded-[50px] text-[#F1B942] hover:bg-[#F1B942] hover:text-white"
              >
                Sync your music
              </button>
            </div>
          </div>
        </div>
      )}

      {((uploadStep < 1 && uploadStep !== 1) ||
        (searchQuery !== "" && uploadStep !== 1) ||
        (type && uploadStep !== 1)) && (
        <div
          className={`${trackData?.length > 0 ? "" : "hidden"}`}
          ref={sectionRef}
        >
          {" "}
          <Tracks
            setIsFilterApplied={setIsFilterApplied}
            setFilter={setFilter}
            setSort={setSort}
            type={type}
            setType={setType}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setIsliked={setIsliked}
            setSongId={setSongId}
            setSongDeleteId={setSongDeleteId}
            isuploadPage={true}
          />{" "}
        </div>
      )}
    </div>
  );
};

export default Upload;
