import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useCallback, useState } from "react";
import CommonListing from "../../collectionPagesComponents/CommonListing";
import DecadesUpdate from "./DecadesUpdateComponent";

const DecadesComponent = ({
  decades,
  setSelectedDecade,
  selectedDecade,
  nav,
  handleDeletePlaylist,
  showEdit,
}) => {
  const [openDecadesModal, setOpenDecadesModal] = useState(false);

  return (
    <div>
      <DecadesUpdate
        openDecadesModal={openDecadesModal}
        setOpenDecadesModal={setOpenDecadesModal}
      />
      {showEdit && (
        <div className={`absolute top-2 right-0 min-w-10  block`}>
          <button className=" w-5 h-5">
            <BorderColorOutlinedIcon
              onClick={() => setOpenDecadesModal(true)}
              className="!text-[#312522]  dark:!text-[#A8A8A8] hover:!text-[#F1B942]"
            />
          </button>
        </div>
      )}
      {nav === 1 ? (
        <div className="relative my-4">
          <div className="   dark:z-0 z-[-2] w-full absolute px-4 top-[24%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="dark:bg-white bg-black h-[2px] w-full"></div>
          </div>
          <Swiper
            slidesPerView={12}
            spaceBetween={30}
            loop={true}
            //   pagination={{
            //     clickable: true,
            //   }}
            breakpoints={{
              250: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 6,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 12,
                spaceBetween: 50,
              },
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {decades?.map((decade, index) => {
              return (
                <SwiperSlide key={decade.id} className=" cursor-pointer ">
                  <div className=" mb-4 flex justify-center w-full">
                    <div
                      className={`w-[35px] h-[35px] bg-[#F1B942] rounded-full  z-50`}
                    ></div>
                  </div>

                  <div
                    className={`text-center  ${
                      selectedDecade?.includes(decade.id)
                        ? "text-[#F1B942] "
                        : "dark:!text-white !text-black"
                    }`}
                  >
                    {decade.v_year}`s
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="swiper-button-next absolute !right-[0px] top-[60%] lg:top-1/2 transform -translate-y-[46%] cursor-pointer text-white text-sm lg:text-[10px] ">
            {/* Add your next arrow icon or text here */}
          </div>
          <div className="swiper-button-prev absolute !left-[0px] top-[60%] lg:top-[1/2] transform -translate-y-[46%] cursor-pointer text-white text-sm lg:text-[10px] ">
            {/* Add your previous arrow icon or text here */}
          </div>
        </div>
      ) : (
        <div>
          <CommonListing
            listing={decades}
            title="Decades"
            flag="profilepagelisting"
            hidetitle={true}
            handleDeletePlaylist={handleDeletePlaylist}
            fromprofile="/collection/decades"
          />
        </div>
      )}
    </div>
  );
};

export default DecadesComponent;
